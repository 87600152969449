import { Row, Col, Container } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

const Typing = () => {
  const { email_id } = useParams();
  const [emailId, setEmailId] = useState("");
  const [cookies, setCookie] = useCookies(["email_id"]);
  const [acces, setAccess] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  let days = 365;
  let expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  setCookie("email_id", email_id, {
    path: "/",
    expires,
  });

  console.log(cookies.email_id);

  const handleEmailId = (event) => {
    setEmailId(event.target.value);
    console.log(event.target.value);
  };

  useEffect(async () => {
    let email = { email_id: cookies.email_id };
    let result = await fetch("/checkAccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(email),
    });
    let email_result = await result.json();
    setAccess(email_result["access"]);
  });

  if (acces != "") {
    if (acces != "access") {
      window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${cookies.email_id}`;
    }
  }

  const handlePassword = (event) => {
    setPassword(event.target.value);
    console.log(event.target.value);
  };

  const userSubmit = async () => {
    navigate(`/typingexamselection`);
  };

  return (
    <>
      <Row className="tm-fr">
        <Col md={12} className="tm-fr-col12">
          <p className="tm-fr-col12p">TCS Typing Software 2023</p>
        </Col>
      </Row>

      <Row className="tm-secr">
        <Col md={4} className="tm-secr-col6">
          <p className="tm-secr-col6-p1">System Name:</p>
          <p className="tm-secr-col6-p2">C005</p>
          <p className="tm-secr-col6-p3">
            Contact Invigilator if the Name and Photograph <br></br> display on
            the screen is not yours.
          </p>
        </Col>
        <Col md={4} className="tm-secr-col4">
          <p className="tm-secr-col6-p1">
            This typing Software is designed based on the real interface of ssc
            exams conducted by TCS
          </p>
        </Col>
        <Col md={4} className="tm-secr-2col6">
          <Col className="tm-secr-50">
            <p className="tm-secr-2col6-p1">Candidate Name:</p>
            <p className="tm-secr-2col6-p2">User 05</p>
            <p className="tm-secr-2col6-p3">
              Subject:{" "}
              <span className="tm-secr-2col6-p3-span">SSC CGL T2 English</span>
            </p>
          </Col>
        </Col>
      </Row>

      <Row className="tm-thr">
        <Col md={4} className="tm-thr-col5"></Col>
        <Col md={8} className="tm-thr-col7">
          <Row className="tm-subthr">
            <Col md={4} className="tm-subthr-col4">
              <form className="form">
                <h5 className="form-h5">Login</h5>
                <input
                  type="text"
                  className="form-input1"
                  value={cookies.email_id}
                  onChange={handleEmailId}
                  readOnly
                />
                <input
                  type="text"
                  className="form-input2"
                  value="pinnacle123"
                  onChange={handlePassword}
                  readOnly
                />
                <input
                  type="submit"
                  className="form-input3"
                  value="Sign In"
                  onClick={userSubmit}
                />
              </form>
            </Col>
            <Col md={1}></Col>
            <Col md={5} className="tm-subthr-2col6">
              <Col className="tm-subthr-2col6-col">
                <p className="tm-subthr-2col6-colp">English</p>
              </Col>
              <Col className="tm-subthr-2col6-col2">
                <p className="tm-subthr-2col6-col2-p">
                  <span className="tm-subthr-2col6-col2-span1">
                    Select Language:{" "}
                  </span>
                  <span className="tm-subthr-2col6-col2-span2">
                    English(US)
                  </span>
                  <span className="tm-subthr-2col6-col2-span3">Move</span>
                </p>
              </Col>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Typing;
