import './Attempted.css'
import { Row, Col } from 'react-bootstrap'
import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import TestSeriesHeader from './TestSeriesHeader'
import TestSeriesFooter from './TestSeriesFooter'
import { useCookies } from "react-cookie"
import { useParams } from 'react-router'
import { FaQuestionCircle, FaFileAlt } from "react-icons/fa";

function AttemptedTest() {
    const navigate = useNavigate()
    const { email_id } = useParams()
    const [AttemptedTests, setAttemptedTests] = useState()
    const memozideHeader = useMemo(() => {
        return <TestSeriesHeader />
    }, []);

    useEffect(async () => {
        let data = [{ 'email_id': email_id }]
        let request = await fetch('/AttemptedTest', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(data)
        })
        let responnse_data = await request.json()
        setAttemptedTests(responnse_data)
    }, [])

    const ViewResultFun = (event) => {
        let exam_mode_id = event.target.getAttribute("exam_mode_id")
        let test_series_id = event.target.getAttribute("test_series_id")
        let posttierid = event.target.getAttribute("post_tier_id")
        let paper_code = event.target.getAttribute("paper_code")
        let title = event.target.getAttribute("title")

        { posttierid == 2 && exam_mode_id == 1 ?  navigate(`/attemptedsection/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(test_series_id)}/${btoa(title)}`) :
        navigate(`/Attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(test_series_id)}/${title}`) }

    } 
                  
    const GOtoSolution = (event) => {
        let exam_mode_ids = event.target.getAttribute("exam_mode_id")
        let testseriesid = event.target.getAttribute("test_series_id")
        let posttierid = event.target.getAttribute("post_tier_id")
        let papercode = event.target.getAttribute("paper_code")
        let testtitle = event.target.getAttribute("title")
        let SubjectID = event.target.getAttribute("subject_id")
         
        { posttierid == 2 && exam_mode_ids == 1 ? navigate(`/solutionsection/${btoa(exam_mode_ids)}/${btoa(papercode)}/${btoa(testseriesid)}/${btoa(testtitle)}/${btoa(SubjectID)}`) :
        navigate(`/solution/${btoa(exam_mode_ids)}/${btoa(papercode)}/${btoa(testseriesid)}/${testtitle}/${btoa(SubjectID)}`) }

    }

    return (
        <>
            {memozideHeader}
            <div className="section-testpass">
                <div className="test-pass-container">
                    <Row className="testpass-heading">
                        <Col md={12}>
                            <h2 className="test-pass-h2">Test Pass</h2>
                        </Col>
                        <Col md={12}>
                            <p className="test-pass-p">Get Online Test Series based on latest TCS pattern with advance features</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="test-pass-div">
                            <img src="https://ssccglpinnacle.com//images/test-pass-section1.png" />
                            <p>Latest TCS Pattern tests Real exam experience</p>
                        </Col>
                        <Col md={3} className="test-pass-div">
                            <img src="https://ssccglpinnacle.com//images/test-pass-section2.png" />
                            <p>Mock Tests, PYP, Chapter wise, Sectional Tests, Result Oriented Tests</p>
                        </Col>
                        <Col md={3} className="test-pass-div">
                            <img src="https://ssccglpinnacle.com//images/test-pass-section3.png" />
                            <p>Detailed and Result Oriented Solution, Immediate Result</p>
                        </Col>
                        <Col md={3} className="test-pass-div">
                            <img src="https://ssccglpinnacle.com//images/test-pass-section4.png" />
                            <p>Detailed Report, Performance Analysis, Improvement Plan</p>
                        </Col>
                    </Row>
                </div>
            </div>

            {
                AttemptedTests == '' ? '' :
                    <div className="section-first-category-exam-page">
                        <div className="test-pass-container">
                            <div className="margin-upper full-width">
                                <h2 className="recommended-heading">Attempted Test</h2>
                                <Row style={{ width: '100%' }}>
                                    {
                                        AttemptedTests && AttemptedTests.map((atmp, at) =>

                                            <Col md={4} style={{ padding: "17px" }} key={at}>
                                                <div className="alltestseries-exam-category-div full-width div-padding">
                                                    <h6 className="left-algn full-width" style={{ fontWeight: '600' }}>{atmp.test_title}</h6>
                                                    <p className="left-algn full-width" style={{ fontSize: '14px' }}>Attempted on {atmp.attempted_date}</p>
                                                    <div className="left-right full-width">
                                                        <span><FaQuestionCircle /> Rank {atmp.Rank}/{atmp.TotalStudent}</span>
                                                        <span><FaFileAlt /> Marks {atmp.Score}/{atmp.TotalMarks}</span>
                                                    </div>              
                                                    <div className="bottom-line"> </div>
                                                    <div className="full-width attempt attemtedtest" style={{ width: '48%' }}>
                                                        <span style={{ color: 'orange', fontWeight: '600' }} onClick={ViewResultFun} test_series_id={atmp.test_series_id} post_tier_id={atmp.post_tier_id} exam_mode_id={atmp.exam_mode_id} paper_code={atmp.paper_code} title={atmp.test_title} expired_date={atmp.expiry_date} expired_time={atmp.expiry_time}>View Result</span>
                                                    </div>
                                                    <div className="full-width attempt attemtedsolution" style={{ width: '48%' }}>
                                                        <span style={{ color: 'orange', fontWeight: '600' }} onClick={GOtoSolution} test_series_id={atmp.test_series_id} post_tier_id={atmp.post_tier_id} exam_mode_id={atmp.exam_mode_id} paper_code={atmp.paper_code} title={atmp.test_title} expired_date={atmp.expiry_date} expired_time={atmp.expiry_time} subject_id={atmp.subject_id}>View Solution</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                </Row>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
export default AttemptedTest;