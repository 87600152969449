import { Row, Col, Container, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Typing.css";
import { useCookies } from "react-cookie";

const TypingParagraph = () => {
  const { exam, category } = useParams();
  const navigate = useNavigate();
  const [Paragraph, setParagraph] = useState([]);
  const [date, setDate] = useState([]);
  const [type, setType] = useState([]);
  const [cookies] = useCookies();

  const startTyping = async (e) => {
    let test_code = e.target.id;
    console.log(test_code);

    navigate(`/typingmodule/${test_code}/${exam}/${category}`);
  };

  useEffect(async () => {
    let dt = { exam: exam };
    let state_res = await fetch("/typingParagraphs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(dt),
    });
    state_res = await state_res.json();
    setParagraph(state_res);
    setDate(date);
    setType(type);
  }, []);

  console.log(Paragraph);

  return (
    <>
      <Row className="tp-fr">
        <Col md={12} className="tp-fr-col12">
          <p className="tp-fr-col12p">{cookies.email_id}</p>
        </Col>
      </Row>
      <Row>
        {Paragraph &&
          Paragraph.map((para, p) => (
            <Col
              md={12}
              key={p}
              id={para.paper_code}
              className="tpara-sr-col12"
              onClick={startTyping}
            >
              <Col>
                <p
                  className="tpara-sr-col12-subcol-p1"
                  id={para.paper_code}
                  onClick={startTyping}
                >
                  {para.paper_code}
                </p>
                <p
                  className="tpara-sr-col12-subcol-p3"
                  id={para.paper_code}
                  onClick={startTyping}
                >
                  {para.date}
                </p>
                <p
                  className="tpara-sr-col12-subcol-p4"
                  id={para.paper_code}
                  onClick={startTyping}
                >
                  {para.type}
                </p>
                <p
                  className="tpara-sr-col12-subcol-p2"
                  id={para.paper_code}
                  onClick={startTyping}
                >
                  Start Typing
                </p>
              </Col>
              <h5
                className="tpara-sr-col12-h5"
                id={para.paper_code}
                onClick={startTyping}
              >
                {para.paragraph}
              </h5>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default TypingParagraph;
