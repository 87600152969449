import { Row, Col } from 'react-bootstrap'
import { FaTelegramPlane, FaWhatsapp, FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaRocketchat } from 'react-icons/fa'


const TestSeriesFooter = () => {   
           
    return (
        <div className='footer' style={{background : "black", color : "white", lineHeight : "2.2", padding : "44px 0px", float : "left"}}>
        <div className="test-pass-container">
            <Row>
                <Col md={2}>
                  <Row>           
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/footer/About-us">About Us</a>  
                    </Col>
                    <Col md={12}>             
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/footer/Terms-and-Conditions">Terms and Conditions</a>  
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/footer/Privacy-Policy">Privacy Policy</a>  
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/footer/Refund-Policy">Refund Policy</a>  
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/footer/Contact-Us">Contact Us</a>  
                    </Col>
                  </Row>     
                </Col>
                <Col md={2}>         
                  <Row>
                    <Col md={12}>       
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/product.php">SSC CPO TIER 1</a>
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/product.php">SSC CPO TIER 2</a>
                    </Col>
                  </Row>
                </Col>
                <Col md={2}>
                  <Row>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/product.php">SSC CGL TIER 1</a> 
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/product.php">SSC CGL TIER 2</a> 
                    </Col>
                  </Row>
                </Col>                                 
                <Col md={2}>
                  <Row>                   
                    <Col md={12}>
                     <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://ssccglpinnacle.com/product.php">RRB NTPC</a> 
                    </Col>
                  </Row>   
                </Col>
                <Col md={2}>
                  <Row>                        
                  <Col md={12}> 
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://support.ssccglpinnacle.com"><FaRocketchat style={{color : "blue", fontSize : "22px", marginRight : "4px"}} /> Support</a>
                    </Col> 
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://t.me/ssccglpinnacleonline"><FaTelegramPlane style={{color : "blue", fontSize : "22px", marginRight : "4px"}} /> Telegram</a>
                    </Col>    
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://api.whatsapp.com/send?phone=918295688244"><FaWhatsapp style={{color : "green", fontSize : "22px", marginRight : "4px"}} /> Whatsapp</a>
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://www.facebook.com/ssccglpinnacle/"><FaFacebook style={{color : "blue", fontSize : "22px", marginRight : "4px"}} /> Facebook</a>
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw"><FaYoutube style={{color : "red", fontSize : "22px", marginRight : "4px"}} /> You Tube</a>
                    </Col>
                    <Col md={12}>
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://www.instagram.com/ssccglpinnacle/"><FaInstagram style={{color : "red", fontSize : "22px", marginRight : "4px"}} /> Instagram</a>
                    </Col>                       
                    <Col md={12}>       
                      <a style={{color : "white", textDecoration : "none", cursor : "Pointer"}} href="https://twitter.com/baljitdhaka"><FaTwitter style={{color : "blue", fontSize : "22px", marginRight : "4px"}} /> Twitter</a>
                    </Col>                     
                  </Row>                              
                </Col>         
                <Col md={2}>                 
                  <Row>
                    <Col md={12}>   
                      support@ssccglpinnacle.com
                    </Col>
                  </Row>
                </Col>
            </Row>           
        </div> 
        </div>                     
    )
}

export default TestSeriesFooter