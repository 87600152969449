import "./mobilestyle.css";
import { Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { IoReorderThreeOutline } from "react-icons/io5";
import React, { useEffect, useState } from "react";

const TestSeriesHeader = () => {
  const [cookies] = useCookies();
  const [isActive, setIsActive] = useState(false);

  const activeThreeoutline = (event) => {
    setIsActive((current) => !current);
  };

  const Fun = (e) => {
    if (e.target.value == "home") {
      window.location.href = `https://ssccglpinnacle.com?email_id=${cookies.email_id}`;
    } else if (e.target.value == "product") {
      window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${cookies.email_id}`;
    } else if (e.target.value == "ebook") {
      window.location.href = `https://ssccglpinnacle.com/ebooks.php?email_id=${cookies.email_id}`;
    } else if (e.target.value == "book") {
      window.location.href = `https://ssccglpinnacle.com/books.php?email_id=${cookies.email_id}`;
    } else if (e.target.value == "app") {
      window.location.href = `https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle`;
    } else if (e.target.value == "testportal") {
      window.location.href = `http://testseries.ssccglpinnacle.com/testseries/${cookies.email_id}`;
    } else if (e.target.value == "myaccount") {
      window.location.href = `https://ssccglpinnacle.com/student/index.php?email_id=${cookies.email_id}`;
    } else if (e.target.value == "attempted") {
      window.location.href = `https://testseries.ssccglpinnacle.com/AttemptedTest/${cookies.email_id}`;
    }
  };
  return (
    <>
      <Row className="testseriesheader">
        <Col className="testseriesheadercolum">
          <img
            className="desktop-float-left mobile-width150"
            src="https://ssccglpinnacle.com/images/logo/pinnaclelogo.png"
            alt="logo"
            style={{ width: "9%" }}
          />

          <button
            className="mobile-display-block mobile-sidebar-button mobile-three-outline desktop-display-none"
            onClick={activeThreeoutline}
          >
            <IoReorderThreeOutline />
          </button>

          <div
            className={
              isActive
                ? "mobile-sidebar mobile-display-block desktop-display-none mobile-sidebar-div"
                : "mobile-sidebar mobile-display-none desktop-display-none"
            }
          >
            <img
              className="mobile-company-logo"
              src="https://ssccglpinnacle.com/images/Pinnacle logo white.png"
              alt="Company Logo"
            />
            <button value="home" onClick={Fun}>
              HOME
            </button>
            <button value="product" onClick={Fun}>
              PRODUCTS
            </button>
            <button value="ebook" onClick={Fun}>
              EBOOKS
            </button>
            <button value="book" onClick={Fun}>
              BOOKS
            </button>
            <button value="app" onClick={Fun}>
              APP
            </button>
            <button value="testportal" onClick={Fun}>
              TEST PORTAL
            </button>
            <button value="myaccount" onClick={Fun}>
              MY ACCOUNT
            </button>
          </div>

          <div className="mobile-display-none desktop-float-left desktop-width850">
            <button value="home" onClick={Fun}>
              HOME
            </button>
            <button value="product" onClick={Fun}>
              PRODUCTS
            </button>
            <button value="ebook" onClick={Fun}>
              EBOOKS
            </button>
            <button value="book" onClick={Fun}>
              BOOKS
            </button>
            <button value="app" onClick={Fun}>
              APP
            </button>
            <button value="testportal" onClick={Fun}>
              TEST PORTAL
            </button>
            <button value="myaccount" onClick={Fun}>
              MY ACCOUNT
            </button>
            <button value="attempted" onClick={Fun}>
              ATTEMPTED TEST
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TestSeriesHeader;

// import "./mobilestyle.css";
// import { Row, Col } from "react-bootstrap";
// import { useCookies } from "react-cookie";
// import { IoReorderThreeOutline } from "react-icons/io5";
// import React, { useEffect, useState } from "react";

// const TestSeriesHeader = () => {
//   const [cookies] = useCookies();
//   const [isActive, setIsActive] = useState(false);

//   const activeThreeoutline = (event) => {
//     setIsActive((current) => !current);
//   };

//   const Fun = (e) => {
//     if (e.target.value == "home") {
//       window.location.href = `https://ssccglpinnacle.com?email_id=${cookies.email_id}`;
//     } else if (e.target.value == "product") {
//       window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${cookies.email_id}`;
//     } else if (e.target.value == "ebook") {
//       window.location.href = `https://ssccglpinnacle.com/ebooks.php?email_id=${cookies.email_id}`;
//     } else if (e.target.value == "book") {
//       window.location.href = `https://ssccglpinnacle.com/books.php?email_id=${cookies.email_id}`;
//     } else if (e.target.value == "app") {
//       window.location.href = `https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle`;
//     } else if (e.target.value == "testportal") {
//       window.location.href = `http://testseries.ssccglpinnacle.com/testseries/${cookies.email_id}`;
//     } else if (e.target.value == "myaccount") {
//       window.location.href = `https://ssccglpinnacle.com/student/index.php?email_id=${cookies.email_id}`;
//     } else if (e.target.value == "attempted") {
//       window.location.href = `https://testseries.ssccglpinnacle.com/AttemptedTest/${cookies.email_id}`;
//     }
//   };
//   return (
//     <>
//       <Row className="testseriesheader">
//         <Col className="testseriesheadercolum">
//           <img
//             className="desktop-float-left mobile-width150"
//             src="https://ssccglpinnacle.com/images/logo/pinnaclelogo.png"
//             alt="logo"
//             style={{ width: "9%" }}
//           />

//           <button
//             className="mobile-display-block mobile-sidebar-button mobile-three-outline desktop-display-none"
//             onClick={activeThreeoutline}
//           >
//             <IoReorderThreeOutline />
//           </button>

//           <div
//             className={
//               isActive
//                 ? "mobile-sidebar mobile-display-block desktop-display-none mobile-sidebar-div"
//                 : "mobile-sidebar mobile-display-none desktop-display-none"
//             }
//           >
//             <img
//               className="mobile-company-logo"
//               src="https://ssccglpinnacle.com/images/Pinnacle logo white.png"
//               alt="Company Logo"
//             />
//             <button value="home" onClick={Fun}>
//               HOME
//             </button>
//             <button value="product" onClick={Fun}>
//               PRODUCTS
//             </button>
//             <button value="ebook" onClick={Fun}>
//               EBOOKS
//             </button>
//             <button value="book" onClick={Fun}>
//               BOOKS
//             </button>
//             <button value="app" onClick={Fun}>
//               APP
//             </button>
//             <button value="testportal" onClick={Fun}>
//               TEST PORTAL
//             </button>
//             <button value="myaccount" onClick={Fun}>
//               MY ACCOUNT
//             </button>
//           </div>

//           <div className="mobile-display-none desktop-float-left desktop-width850">
//             <button value="home" onClick={Fun}>
//               HOME
//             </button>
//             <button value="product" onClick={Fun}>
//               PRODUCTS
//             </button>
//             <button value="ebook" onClick={Fun}>
//               EBOOKS
//             </button>
//             <button value="book" onClick={Fun}>
//               BOOKS
//             </button>
//             <button value="app" onClick={Fun}>
//               APP
//             </button>
//             <button value="testportal" onClick={Fun}>
//               TEST PORTAL
//             </button>
//             <button value="myaccount" onClick={Fun}>
//               MY ACCOUNT
//             </button>
//             <button value="attempted" onClick={Fun}>
//               ATTEMPTED TEST
//             </button>
//           </div>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default TestSeriesHeader;
