import React, { useState, useEffect } from 'react'

const CountDownTimer5 = ({ hoursMinSecs, pause, rmTimeFun }) => {
    
    const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[hrass, minass, secass], setTime] = useState([hours, minutes, seconds]);
                          
    console.log(hoursMinSecs)
                       
    console.log(hours)
    console.log(minutes)    
    console.log(seconds)        
    
    console.log(hrass)
    console.log(minass)
    console.log(secass)                        
                                                                
    const tick = () => {
        if (hrass == 0 && minass == 0 && secass == 0)
            reset()
        else if (minass == 0 && secass == 0) {
            setTime([hrass - 1, 59, 59]);
        } else if (secass == 0) {
            setTime([hrass, minass - 1, 59]);          
        } else {
            setTime([hrass, minass, secass - 1]);
        }
    };               
                                     
    const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
              
    React.useEffect(() => {     
        const timerId = setInterval(() => { if (!pause) { tick() } }, 950);
        return () => clearInterval(timerId);                              
    });
    
    console.log(hrass)
    console.log(minass)
    console.log(secass)
        
    let rTem = hrass.toString().padStart(2, '0') + ':' + minass.toString().padStart(2, '0') + ':' + secass.toString().padStart(2, '0');
      
    console.log(rTem)
                   
    useEffect(() => {      
        rmTimeFun(rTem)
    }, [secass])

    return (
        <>
            {`${hrass.toString().padStart(2, '0')}:${minass
            .toString()         
            .padStart(2, '0')}:${secass.toString().padStart(2, '0')}`} 

            {/* {`<span>${hrss.toString().padStart(2, '0')}</span>:<span>${minss
                .toString()
                .padStart(2, '0')}</span>:<span>${secss.toString().padStart(2, '0')}</span>`} */}
        </>
    );
}

export default CountDownTimer5;