import './Header1.css'
import './TestSeries.css'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import TestOverview from './TestOverview'
import TestSeriesHeader from './TestSeriesHeader'
import TestSeriesFooter from './TestSeriesFooter'
import SpecificTestseries from './SpecificTestseries'
import Faq from './Faq'
import TestSeriesAbout from './TestSeriesAbout'

function TestseriesList() {
	const { exam_mode_id, exam_post_id } = useParams()
	const [TierName, setPostTierName] = useState()
	const [ExamID, setExamID] = useState()

	useEffect(async () => {
		let tier_ids = { 'tier_id': exam_post_id }
		let res = await fetch('/Breadcrump', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
			},
			body: JSON.stringify(tier_ids)
		})
		let tier = await res.json()
		setExamID(tier[0]['ExamId'])
		setPostTierName(tier[0]['PostTierId'])
	}, [])

	return (
		<>
			<TestSeriesHeader />
			<TestOverview ExamID={ExamID} TierID={exam_post_id} TierName={TierName} exam_mode_id={exam_mode_id} />
			<SpecificTestseries exam_post_id={exam_post_id} exam_mode_id={exam_mode_id} />
			<TestSeriesAbout />
			<Faq />
			<TestSeriesFooter />
		</>
	)
}

export default TestseriesList