import "./Header1.css";
import "./TestSeries.css";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestTimer from "./TestTimer";
import { FaArrowRight } from "react-icons/fa";
import TestSeriesHeader from "./TestSeriesHeader";
import TestSeriesFooter from "./TestSeriesFooter";
import { useCookies } from "react-cookie";

function CategoryExam() {
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [ExamPostData, setExamPostData] = useState([]);
  const [ExamPostLogoData, setExamPostLogoData] = useState([]);
  const [ExamPostTierIdData, setExamPostTierIdData] = useState([]);
  const { id } = useParams();
  const [freeTestData, setFreeTestData] = useState([]);
  const [FreeTestDetailsData, setFreeTestDetailsData] = useState([]);
  const [RelatedTestDataWeb, setRelatedTestDataWeb] = useState([]);
  const [ExamPostId, setExamPostId] = useState([]);
  const [LiveTestData, setLiveTestData] = useState([]);
  const [UpcommingTestData, seUpcommingTestData] = useState([]);
  const [ExpiredTestData, setExpiredTestData] = useState([]);
  const [mobile, setMobile] = useState("");
  const [Otp, setOtp] = useState();
  const [EnterOtp, setEnterOtp] = useState();
  const [TestType, setTestType] = useState("Register");
  const [EmailID, setEmailID] = useState();
  const [FullName, setFullName] = useState();
  const [TestSeriesId, setTestSeriesId] = useState();
  const [ExamModeId, setExamModeId] = useState();
  const [paper_code, setPaperCode] = useState();
  const [RegisteredStd, setRegisteredStd] = useState();
  const [RegisteredUser, setRegisteredUser] = useState();
  const [RegisteredType, setRegisteredType] = useState("Register");
  const [UpcommingRegistered, setUpcommingRegistered] = useState("Register");
  const [TestAccess, setTestAccess] = useState();
  const [show, setShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [showEmail, setEmailShow] = useState(false);
  const [AttemptedTests, setAttemptedTests] = useState([]);
  const [PCode, setPCode] = useState();
  const [RegisterData, setRegisterData] = useState();
  const [TestTitle, setTestTitle] = useState();

  var url = window.location.href;
  var user = url.split("?UserID=");
  var userids;
  var userids = user != undefined ? (userids = user["1"]) : "";
  var useridss;
  useridss = userids != undefined ? userids.split("&CampaignID=") : "";
  var userid = useridss["0"];
  var useridsss = useridss["1"];
  var campaign;
  var campaign = useridsss != undefined ? useridsss.split("&BannerID=") : "";
  var campaignid = campaign["0"];
  var bannerid = campaign["1"];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(async () => {
    let exam_details_request = [{ email_id: cookies.email_id, exam_id: id }];
    let exam_info = await fetch("/examWebApi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(exam_details_request),
    });
    let exam_infodssdfsd = await exam_info.json();
    setData(exam_infodssdfsd);
    setExamPostTierIdData(
      exam_infodssdfsd[0]["exam_post"][0]["exam_post_tier_id"]
    );
    setExamPostData(exam_infodssdfsd[0]["exam_post"]);
    setExamPostLogoData(exam_infodssdfsd[0]["logo"]);
  }, []);

  useEffect(async () => {
    let related_test_data_web = [{ email_id: cookies.email_id, exam_id: id }];
    let related_test_data_web_request = await fetch("/RelatedExam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(related_test_data_web),
    });
    let related_test_data_web_response =
      await related_test_data_web_request.json();
    setRelatedTestDataWeb(related_test_data_web_response);
    if (related_test_data_web_response != "") {
      setExamPostId(related_test_data_web_response[0]["exam_post_tier_id"]);
    }
  }, []);

  useEffect(async () => {
    let free_test_data = [{ email_id: cookies.email_id, exam_id: id }];
    let free_test_data_request = await fetch("/FreeTestsWeb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(free_test_data),
    });
    let free_test_data_response = await free_test_data_request.json();
    setFreeTestData(free_test_data_response);
    setFreeTestDetailsData(free_test_data_response[0]["details"]);
  }, []);

  useEffect(async () => {
    let test_access = [{ email_id: cookies.email_id }];
    let test_access_request = await fetch("/testSeriesAccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(test_access),
    });
    let test_access_response = await test_access_request.json();
    setTestAccess(test_access_response[0]["user_status"]);
  }, []);

  // useEffect(() => {
  //   clearTimer();
  // }, []);

  console.log("REMOVED CLEAR TIMER");

  async function postTier(e) {
    let tier_id = e.target.dataset.tier;
    navigate("/TestSeriesSingle/" + tier_id);
  }

  async function viewAll(e) {
    let tier_id = e.target.dataset.tier;
    navigate("/TestSeriesSingle/" + tier_id);
  }

  // async function clearTimer(e) {
  //   console.log("CLEARTIMER FUNCTION STARTED");
  //   let live_test_email_id = [{ email_id: cookies.email_id }];
  //   console.log("CLEARTIMER FUNCTION AFTER COOKIES EMAIL");
  //   let live_test_request = await fetch("/MegaMockChallengeTest", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
  //     },
  //     body: JSON.stringify(live_test_email_id),
  //   });
  //   console.log("CLEARTIMER FUNCTION AFTER FETCH REQUEST");
  //   let live_test_response = await live_test_request.json();
  //   console.log("CLEARTIMER FUNCTION AFTER FETCH REQUEST", live_test_response);
  //   setRegisteredStd(live_test_response[0]["registered_std"]);
  //   if (live_test_response[0]["LiveTests"] != "") {
  //     setLiveTestData(live_test_response[0]["LiveTests"]);
  //     setTestSeriesId(live_test_response[0]["LiveTests"][0]["test_series_id"]);
  //     setExamModeId(live_test_response[0]["LiveTests"][0]["exam_mode_id"]);
  //     setPaperCode(live_test_response[0]["LiveTests"][0]["paper_code"]);
  //     if (live_test_response[0]["LiveTests"][0]["left_days"] == "live") {
  //       setTestType("Start Test");
  //     }
  //     seUpcommingTestData(live_test_response[0]["UpcommingTests"]);
  //     setExpiredTestData(live_test_response[0]["ExpiredTests"]);
  //     setAttemptedTests(live_test_response[0]["AttemptedTests"]);
  //     setRegisteredStd(live_test_response[0]["LiveTests"][0]["registered_std"]);
  //     setRegisteredUser(
  //       live_test_response[0]["LiveTests"][0]["registered_user"]
  //     );
  //   }
  // }

  const handleUser = async (e) => {
    let paper_code = e.target.value;
    let std_details = [
      {
        mobile,
        Otp,
        EmailID: cookies.email_id,
        test_series_id: TestSeriesId,
        exam_mode_id: ExamModeId,
        paper_code,
      },
    ];
    await fetch("/studentInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(std_details),
    });
    setRegisteredType("Registered");
  };

  const handleUpcomingUser = async (e) => {
    let paper_code = e.target.value;
    let std_details = [
      {
        mobile,
        Otp,
        EmailID: cookies.email_id,
        test_series_id: TestSeriesId,
        exam_mode_id: ExamModeId,
        paper_code,
      },
    ];
    await fetch("/studentInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(std_details),
    });
    setPCode(paper_code);
    setUpcommingRegistered("Registered");
  };

  const [BuyTest, setBuyTest] = useState();

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    console.log(e.target.value);
    console.log(e.target.id);
    console.log(e.target.getAttribute("data-id"));
    setRegisterData(e.target.value);
    setTestTitle(e.target.getAttribute("data-id"));
    setBuyTest(e.target.id);
    setShow(true);
  };

  const handleOtp = async () => {
    setOtpShow(true);
    setShow(false);
    let otp_data = { mobile: mobile, name: "Student" };
    let otp_request = await fetch("/otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(otp_data),
    });
    let otp_response = await otp_request.json();
    setOtp(otp_response.OTP);
  };

  const handleEmail = () => {
    if (EnterOtp == Otp) {
      setOtpShow(false);
      setEmailShow(true);
    } else {
      alert("Please enter the OTP sent to " + mobile);
    }
  };

  const BuyFun = () => {
    userid != undefined
      ? (window.location.href =
          "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-?UserID=" +
          userid +
          "&CampaignID=" +
          campaignid +
          "&BannerID=" +
          bannerid)
      : (window.location.href =
          "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-");
  };

  const handleEmailSubmit = async () => {
    let days = 365;
    let expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    setCookie("email_id", EmailID, {
      path: "/",
      expires,
    });
    setEmailShow(false);
    let std_details = [
      {
        mobile,
        Otp,
        EmailID: EmailID,
        test_series_id: TestSeriesId,
        exam_mode_id: ExamModeId,
        paper_code,
        FullName,
      },
    ];
    await fetch("/studentInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(std_details),
    });
    setPCode(paper_code);
    setRegisteredType("Registered");
    //if (BuyTest == 'buytest' && RegisteredStd != '0') {
    if (BuyTest == "buytest") {
      window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${EmailID}`;
    } else {
      if (RegisterData == 0) {
        navigate(
          `/Instruction/${btoa(ExamModeId)}/${btoa(paper_code)}/${btoa(
            TestSeriesId
          )}/${btoa(TestTitle)}`
        );
      } else {
        setRegisteredType("Registered");
      }
    }
  };

  const MobileFun = (e) => {
    setMobile(e.target.value);
  };

  const OtpFun = (e) => {
    setEnterOtp(e.target.value);
  };

  const setTest = (tst) => {
    setTestType(tst);
  };

  const startTest = async (event) => {
    let live_test_paper = [{ paper_code: paper_code }];
    let live_test_request = await fetch("/LiveTestCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(live_test_paper),
    });
    await live_test_request.json();

    let test_title = event.target.getAttribute("test_title");
    navigate(
      `/Instruction/${btoa(ExamModeId)}/${btoa(paper_code)}/${btoa(
        TestSeriesId
      )}/${btoa(test_title)}`
    );
  };

  const start = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let test_title = event.target.getAttribute("title");
    navigate(
      `/Instruction/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
        test_series_id
      )}/${btoa(test_title)}`
    );
  };

  const HomeFun = () => {
    window.location.href = "https://ssccglpinnacle.com";
  };

  const TestSeriesFun = () => {
    if (cookies.email_id == undefined || cookies.email_id == "undefined") {
      navigate(`/testseries`);
    } else {
      navigate(`/testseries/${cookies.email_id}`);
    }
  };

  const ViewResultFun = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let paper_code = event.target.getAttribute("paper_code");
    let title = event.target.getAttribute("title");
    let expir_date = event.target.getAttribute("expired_date");
    let expir_time = event.target.getAttribute("expired_time");

    var todayDate = new Date().toISOString().slice(0, 10);

    if (expir_date < todayDate) {
      navigate(
        `/Attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
          test_series_id
        )}/${btoa(title)}`
      );
    } else {
      navigate(
        `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
          test_series_id
        )}`
      );
    }
  };

  const ValidationFun = () => {
    alert(`Please Enter Valid Mobile Number`);
  };

  return (
    <>
      <TestSeriesHeader />
      <div className="section-first-category-exam-page font-size12 margin-top10 margin-bottom10">
        <div className="test-pass-container">
          <ul className="breadcrumb">
            <li
              className="secondary"
              onClick={HomeFun}
              style={{ cursor: "pointer" }}
            >
              Home
            </li>
            <li
              className="secondary"
              onClick={TestSeriesFun}
              style={{ cursor: "pointer" }}
            >
              Test Series
            </li>
            <li className="gray">
              {id == 1 ? "SSC" : id == 2 ? "Delhi Police" : "Railway"}
            </li>
          </ul>
          <h2 className="ssc-test-series-heading">
            SSC Yearly Test Series 2023
          </h2>
          <p className="online-test-series-p">
            Attempt online test series for upcoming SSC exams. Start with a free
            mock test now Mock Tests I Previous Year TCS Papers I Sectional
            tests I Chapter wise Tests I Misc. Tests
          </p>
          <Row className="margin-upper margin-none">
            <Col md={8} className="no-padding">
              <Row className="margin-none">
                <Col md={10} className="no-padding">
                  <h2 className="recommended-heading">Recommended Exams</h2>
                  <p className="recommended-exam-p">
                    View test series for upcoming exams : Mock tests I Previous
                    Year TCS Tests I Sectional tests I Chapter wise I
                    Challenging Tests
                  </p>
                </Col>
                <Col md={2}>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={viewAll}
                    data-tier={ExamPostTierIdData}
                    className="recomended-viewall secondary"
                  >
                    View All
                  </p>
                </Col>
              </Row>
              <div className="main-div">
                <Row className="margin-none">
                  <Carousel
                    responsive={responsive}
                    autoPlay="false"
                    autoPlaySpeed={100000000}
                  >
                    {ExamPostData.map((expm, l) => (
                      <Col md={12} style={{ padding: "17px" }} key={l}>
                        <div
                          className="alltestseries-exam-category-div full-width"
                          data-tier={expm.exam_post_tier_id}
                          onClick={postTier}
                        >
                          <img
                            className="exam-category-img margin-bottom20"
                            data-tier={expm.exam_post_tier_id}
                            src={ExamPostLogoData}
                          />
                          <p
                            className="exam-category-p line-height16 font-size15-weight-600"
                            data-tier={expm.exam_post_tier_id}
                          >
                            {expm.exam_post_tier}
                          </p>
                          <p
                            className="exam-category-p line-height16 font-size15"
                            data-tier={expm.exam_post_tier_id}
                          >
                            Total Test: {expm.test_count}
                          </p>
                          <p
                            className="exam-category-p line-height16 font-size15"
                            data-tier={expm.exam_post_tier_id}
                          >
                            Mock Test: {expm.full_test_count}
                          </p>
                          <p
                            className="exam-category-p line-height16 font-size15"
                            data-tier={expm.exam_post_tier_id}
                          >
                            Chapter Test: {expm.chapter_test_count}
                          </p>
                          <p
                            className="exam-category-p line-height16 font-size15"
                            data-tier={expm.exam_post_tier_id}
                          >
                            Sectional Test: {expm.subject_test_count}
                          </p>
                          <p
                            className="exam-category-p line-height16 font-size15"
                            data-tier={expm.exam_post_tier_id}
                          >
                            PY Test: {expm.previous_test_count}
                          </p>
                        </div>
                      </Col>
                    ))}
                  </Carousel>
                </Row>
                <Row className="margin-none">
                  {TestAccess == 1 ? (
                    ""
                  ) : (
                    <button
                      className="unlock-button unlock-button-padding unlock-width"
                      id="buytest"
                      onClick={RegisteredStd == "0" ? handleShow : BuyFun}
                    >
                      Unlock all exams
                    </button>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="bg-background">
                <p className="category-yearly-test-pass">
                  Buy Yearly test pass to unlock all SSC exams tests: 1000+
                  tests
                </p>
                <Row className="margin-none">
                  <Col md={6} className="no-padding padding-left15">
                    <div className="full-width">
                      <svg
                        className="bg-secondary bg-width"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        />
                      </svg>
                      <p className="p-color p-size">Latest TCS Pattern</p>
                    </div>
                    <div className="full-width">
                      <svg
                        className="bg-secondary bg-width"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        />
                      </svg>
                      <p className="p-color p-size">
                        Comprehensive test series
                      </p>
                    </div>
                    <div className="full-width">
                      <svg
                        className="bg-secondary bg-width"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        />
                      </svg>
                      <p className="p-color p-size">Exam Oriented Solution</p>
                    </div>
                    <div className="full-width">
                      <svg
                        className="bg-secondary bg-width"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        />
                      </svg>
                      <p className="p-color p-size">Detailed Analysis</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <img src="https://ssccglpinnacle.com/images/Group 36.png" />
                  </Col>
                </Row>
                {TestAccess == 1 ? (
                  ""
                ) : (
                  <button
                    className="unlock-button unlock-button-padding"
                    id="buytest"
                    onClick={RegisteredStd == "0" ? handleShow : BuyFun}
                  >
                    Unlock all exams
                  </button>
                )}
              </div>
            </Col>
          </Row>

          <div className="margin-upper full-width">
            <h2 className="recommended-heading">
              Attempt Free SSC Exams Online Tests
            </h2>
            <p className="recommended-exam-p">
              Start your Free online tests here
            </p>
            <Row>
              <Carousel
                responsive={responsive}
                autoPlay="false"
                autoPlaySpeed={100000000}
              >
                {FreeTestDetailsData.map((fdm, x) => (
                  <Col md={11} style={{ padding: "17px" }} key={x}>
                    <div className="alltestseries-exam-category-div full-width div-padding">
                      <h6 className="left-algn full-width">{fdm.Title}</h6>
                      {/*  <p className="left-algn full-width">Expires on {fdm.expire_date}</p> */}
                      <p>{fdm.test_type}</p>
                      <div className="bottom-line"></div>
                      <div className="left-right full-width">
                        <span>Questions</span>
                        <span>{fdm.Questions}</span>
                      </div>
                      <div className="left-right full-width">
                        <span>Time(mins)</span>
                        <span>{fdm.Time}</span>
                      </div>
                      <div className="left-right full-width">
                        <span>Maximum Marks</span>
                        <span>{fdm.Marks}</span>
                      </div>
                      <div className="bottom-line"> </div>
                      <div
                        onClick={start}
                        test_series_id={fdm.test_series_id}
                        exam_mode_id={fdm.exam_mode_id}
                        paper_code={fdm.paper_code}
                        title={fdm.Title}
                        className="full-width attempt"
                      >
                        <span
                          test_series_id={fdm.test_series_id}
                          exam_mode_id={fdm.exam_mode_id}
                          paper_code={fdm.paper_code}
                          title={fdm.Title}
                        >
                          Attempt
                        </span>
                        <span
                          test_series_id={fdm.test_series_id}
                          exam_mode_id={fdm.exam_mode_id}
                          paper_code={fdm.paper_code}
                          title={fdm.Title}
                          style={{ fontSize: "12px" }}
                        >
                          <FaArrowRight />
                        </span>
                      </div>
                    </div>
                  </Col>
                ))}
              </Carousel>
            </Row>
          </div>
        </div>
      </div>
      <div className="bg-skyblue full-width div-padding">
        <div className="test-pass-container">
          <Row className="margin-none">
            <Col md={6} className="center-align">
              <Row className="margin-none">
                <Col md={12}>
                  <img src="https://grdp.co/cdn-cgi/image/width=176,height=156,quality=100,f=auto/https://gs-post-images.grdp.co/2021/8/group-4-2x-img1629278264534-38.png-rs-high-webp.png" />
                  <p>Aaj ka Champion kaun</p>
                  <p>All India Live Mock Test</p>
                </Col>
                <Col md={12}>
                  <Row className="margin-none">
                    <Col
                      md={3}
                      className="margin-center right-border center-align fnt13 padding-left15"
                    >
                      <svg
                        className="bg-secondary bg-width center-align margin-center fln"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        ></path>
                      </svg>
                      <span className="full-width center-align">
                        Latest TCS Pattern
                      </span>
                    </Col>
                    <Col
                      md={3}
                      className="margin-center right-border center-align fnt13"
                    >
                      <svg
                        className="bg-secondary bg-width center-align margin-center fln"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        ></path>
                      </svg>
                      <span className="full-width center-align">
                        Real Exam exposure
                      </span>
                    </Col>
                    <Col
                      md={3}
                      className="margin-center right-border center-align fnt13"
                    >
                      <svg
                        className="bg-secondary bg-width center-align margin-center fln"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        ></path>
                      </svg>
                      <span className="full-width center-align">
                        Compete with thousands of students
                      </span>
                    </Col>
                    <Col md={3} className="margin-center center-align fnt13">
                      <svg
                        className="bg-secondary bg-width center-align margin-center fln"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.133"
                        height="20.533"
                        viewBox="0 0 20.133 20.533"
                      >
                        <path
                          id="ios-checkmark-circle"
                          d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                          transform="translate(-3.375 -3.375)"
                          fill="#e71134"
                        ></path>
                      </svg>
                      <span className="full-width center-align">
                        Detailed performance analysis
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              {LiveTestData.map((livetst, l) => (
                <>
                  <div
                    className="alltestseries-exam-category-div full-width margin-center div-padding wd80 ml10"
                    key={l}
                  >
                    <img
                      className="exam-category-img"
                      src="https://ssccglpinnacle.com/images/ssc-logo.png"
                    />
                    <h5 className="center-align full-width">
                      {livetst.test_title}
                    </h5>
                    <Row className="full-width">
                      <Col md={4} className="no-padding">
                        <p className="right-border">
                          {livetst.TotalQuestions} Questions
                        </p>
                      </Col>
                      <Col md={4} className="no-padding">
                        <p className="right-border">
                          {livetst.TotalMarks} Marks
                        </p>
                      </Col>
                      <Col md={4}>
                        <p className="right-border">
                          {livetst.TotalTime} Minutes
                        </p>
                      </Col>
                    </Row>
                    <Row className="full-width margin-left0 margin-right0 rb3">
                      {TestType == "Start Test" ||
                      livetst.left_days == "live" ? (
                        <Col md={12} className="right-border liveclass">
                          <p className="margin-bottom0">Test is Live now</p>
                        </Col>
                      ) : RegisteredStd == "1" && RegisteredUser == "1" ? (
                        <Col md={12} className="right-border">
                          <p
                            className="margin-bottom0"
                            style={{ fontSize: "13px", marginTop: "2%" }}
                          >
                            Starts In
                          </p>
                          <p style={{ fontWeight: "600" }}>
                            {livetst.left_days == "24" ? (
                              <TestTimer
                                hours={livetst.hours}
                                mins={livetst.minutes}
                                secs={livetst.seconds}
                                setTest={setTest}
                              />
                            ) : livetst.left_days == "live" ? (
                              "Live"
                            ) : (
                              livetst.left_days
                            )}
                          </p>
                        </Col>
                      ) : (
                        <>
                          <Col md={6} className="right-border">
                            <p className="margin-bottom0">Starts On</p>
                            <p>{livetst.start_date}</p>
                          </Col>
                          <Col md={6}>
                            <p style={{ margin: "0" }}>Time Left</p>
                            <p>
                              {livetst.left_days == "24" ? (
                                <TestTimer
                                  hours={livetst.hours}
                                  mins={livetst.minutes}
                                  secs={livetst.seconds}
                                  setTest={setTest}
                                />
                              ) : livetst.left_days == "live" ? (
                                "Live"
                              ) : (
                                livetst.left_days + " to go"
                              )}
                            </p>
                          </Col>
                        </>
                      )}
                    </Row>
                    <div className="full-width">
                      {livetst.left_days == "live" && RegisteredStd == "1" ? (
                        <button
                          className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
                          test_title={livetst.test_title}
                          onClick={startTest}
                        >
                          Start Test
                        </button>
                      ) : RegisteredStd == "1" && RegisteredUser == "1" ? (
                        <button className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt reg-user">
                          Registered
                        </button>
                      ) : RegisteredStd == "1" && RegisteredUser == "0" ? (
                        <button
                          className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
                          value={livetst.paper_code}
                          onClick={handleUser}
                        >
                          {RegisteredType}
                        </button>
                      ) : (
                        <button
                          className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
                          value={RegisteredStd}
                          data-id={livetst.test_title}
                          onClick={handleShow}
                        >
                          {RegisteredType}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </Col>
          </Row>
        </div>
      </div>

      {UpcommingTestData == "" ? (
        ""
      ) : (
        <div className="section-first-category-exam-page">
          <div className="test-pass-container">
            <div className="margin-upper full-width">
              <h2 className="recommended-heading">
                Upcoming Mega Mock Challenge
              </h2>
              <div className="main-div">
                <div className="scroller-button top34-percent transform0">
                  <svg
                    id="arrow-ios-forward-outline"
                    width="10.589"
                    height="21.047"
                    viewBox="0 0 10.589 21.047"
                  >
                    <path
                      id="arrow-ios-forward-outline-2"
                      data-name="arrow-ios-forward-outline"
                      d="M15,28.5a1.5,1.5,0,0,1-1.155-2.46L20.565,18l-6.48-8.055A1.533,1.533,0,1,1,16.5,8.055l7.245,9a1.5,1.5,0,0,1,0,1.905l-7.5,9A1.5,1.5,0,0,1,15,28.5Z"
                      transform="translate(-13.497 -7.456)"
                      fill="#ed5f69"
                    ></path>
                  </svg>
                </div>
                <Row className="main-div-scroller" style={{ width: "100%" }}>
                  {UpcommingTestData &&
                    UpcommingTestData.map((fdm, x) => (
                      <>
                        <Col md={4} key={x}>
                          <div className="alltestseries-exam-category-div full-width div-padding">
                            <h6
                              className="left-algn full-width"
                              style={{ fontWeight: "600" }}
                            >
                              {fdm.test_title}
                            </h6>
                            <p
                              className="left-algn full-width"
                              style={{ fontSize: "14px" }}
                            >
                              Starts on {fdm.start_date}
                            </p>
                            <div className="left-right full-width">
                              <span>8800+ users have already registered</span>
                            </div>
                            <div className="bottom-line"> </div>
                            {fdm.registered_std == "1" &&
                            fdm.registered_user == "1" ? (
                              <div className="full-width attempt">
                                <span>Registered</span>
                              </div>
                            ) : fdm.registered_std == "1" &&
                              fdm.registered_user == "0" ? (
                              <button
                                value={fdm.paper_code}
                                onClick={handleUpcomingUser}
                                className="full-width attempt"
                              >
                                {fdm.paper_code == PCode
                                  ? UpcommingRegistered
                                  : "Register"}
                                <span
                                  style={{ fontSize: "12px" }}
                                  value={fdm.paper_code}
                                >
                                  <FaArrowRight />
                                </span>
                              </button>
                            ) : (
                              <div
                                className="full-width attempt"
                                onClick={handleShow}
                              >
                                <span>{RegisteredType}</span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}

      {ExpiredTestData == "" ? (
        ""
      ) : (
        <div className="section-first-category-exam-page">
          <div className="test-pass-container">
            <div className="margin-upper full-width">
              <h2 className="recommended-heading">
                Expired Mega Mock Challenge
              </h2>
              <Row className="main-div-scroller" style={{ width: "100%" }}>
                <Carousel
                  responsive={responsive}
                  autoPlay="false"
                  autoPlaySpeed={100000000}
                >
                  {ExpiredTestData &&
                    ExpiredTestData.map((exp, ex) => (
                      <Col md={12} style={{ padding: "17px" }} key={ex}>
                        <div className="alltestseries-exam-category-div full-width div-padding">
                          <h6
                            className="left-algn full-width"
                            style={{ fontWeight: "600" }}
                          >
                            {exp.test_title}
                          </h6>
                          <p
                            className="left-algn full-width"
                            style={{ fontSize: "14px" }}
                          >
                            Expired on {exp.expiry_date}
                          </p>
                          <div className="left-right full-width">
                            <span>8800+ users have already registered</span>
                          </div>
                          <div className="bottom-line"> </div>
                          <div className="full-width attempt">
                            <span style={{ color: "gray", fontWeight: "600" }}>
                              Expired
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Carousel>
              </Row>
            </div>
          </div>
        </div>
      )}

      {AttemptedTests == "" ? (
        ""
      ) : (
        <div className="section-first-category-exam-page">
          <div className="test-pass-container">
            <div className="margin-upper full-width">
              <h2 className="recommended-heading">
                Attempted Mega Mock Challenge
              </h2>
              <Row className="main-div-scroller" style={{ width: "100%" }}>
                <Carousel
                  responsive={responsive}
                  autoPlay="false"
                  autoPlaySpeed={100000000}
                >
                  {AttemptedTests &&
                    AttemptedTests.map((atmp, at) => (
                      <Col md={12} style={{ padding: "17px" }} key={at}>
                        <div className="alltestseries-exam-category-div full-width div-padding">
                          <h6
                            className="left-algn full-width"
                            style={{ fontWeight: "600" }}
                          >
                            {atmp.test_title}
                          </h6>
                          <p
                            className="left-algn full-width"
                            style={{ fontSize: "14px" }}
                          >
                            Attempted on {atmp.attempted_date}
                          </p>
                          <div className="left-right full-width">
                            <span>8800+ users have already registered</span>
                          </div>
                          <div className="bottom-line"> </div>
                          <div className="full-width attempt">
                            <span
                              style={{ color: "orange", fontWeight: "600" }}
                              onClick={ViewResultFun}
                              test_series_id={atmp.test_series_id}
                              exam_mode_id={atmp.exam_mode_id}
                              paper_code={atmp.paper_code}
                              title={atmp.test_title}
                              expired_date={atmp.expiry_date}
                              expired_time={atmp.expiry_time}
                            >
                              View Result
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Carousel>
              </Row>
            </div>
          </div>
        </div>
      )}

      <div className="full-width mt55 margin-bottom30">
        <div className="test-pass-container">
          <Row className="margin-none">
            <Col md={8} className="no-padding">
              {RelatedTestDataWeb == ""
                ? ""
                : RelatedTestDataWeb.map((rtdm, o) => (
                    <>
                      <Row className="margin-none">
                        <Col md={10} className="no-padding">
                          <h2 className="recommended-heading">Other Exams</h2>
                          <p className="recommended-exam-p">
                            View Test series of other exams in the test pass
                          </p>
                        </Col>
                        <Col md={2}>
                          <p
                            onClick={viewAll}
                            data-tier={ExamPostId}
                            style={{ cursor: "pointer" }}
                            className="recomended-viewall secondary"
                          >
                            View All
                          </p>
                        </Col>
                      </Row>
                      <div className="main-div">
                        <Row className="margin-none">
                          <Col md={4} key={o}>
                            <div
                              className="alltestseries-exam-category-div full-width"
                              data-tier={rtdm.exam_post_tier_id}
                              onClick={postTier}
                            >
                              <img
                                className="exam-category-img"
                                data-tier={rtdm.exam_post_tier_id}
                                src={rtdm.logo}
                              />
                              <p
                                className="exam-category-p"
                                data-tier={rtdm.exam_post_tier_id}
                              >
                                {rtdm.post_name}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ))}
              <Row className="margin-none">
                {TestAccess == 1 ? (
                  ""
                ) : (
                  <button
                    className="unlock-button unlock-button-padding unlock-width"
                    id="buytest"
                    onClick={RegisteredStd == "0" ? handleShow : BuyFun}
                  >
                    Unlock all exams
                  </button>
                )}
              </Row>
            </Col>
            {TestAccess == 1 ? (
              ""
            ) : (
              <Col md={4}>
                <div className="bg-background">
                  <p className="category-yearly-test-pass">
                    Buy Yearly test pass to unlock all SSC exams tests: 1000+
                    tests
                  </p>
                  <Row className="margin-none">
                    <Col md={6} className="no-padding padding-left15">
                      <div className="full-width">
                        <svg
                          className="bg-secondary bg-width"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.133"
                          height="20.533"
                          viewBox="0 0 20.133 20.533"
                        >
                          <path
                            id="ios-checkmark-circle"
                            d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                            transform="translate(-3.375 -3.375)"
                            fill="#e71134"
                          />
                        </svg>
                        <p className="p-color p-size">Latest TCS Pattern</p>
                      </div>
                      <div className="full-width">
                        <svg
                          className="bg-secondary bg-width"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.133"
                          height="20.533"
                          viewBox="0 0 20.133 20.533"
                        >
                          <path
                            id="ios-checkmark-circle"
                            d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                            transform="translate(-3.375 -3.375)"
                            fill="#e71134"
                          />
                        </svg>
                        <p className="p-color p-size">
                          Comprehensive test series
                        </p>
                      </div>
                      <div className="full-width">
                        <svg
                          className="bg-secondary bg-width"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.133"
                          height="20.533"
                          viewBox="0 0 20.133 20.533"
                        >
                          <path
                            id="ios-checkmark-circle"
                            d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                            transform="translate(-3.375 -3.375)"
                            fill="#e71134"
                          />
                        </svg>
                        <p className="p-color p-size">Exam Oriented Solution</p>
                      </div>
                      <div className="full-width">
                        <svg
                          className="bg-secondary bg-width"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.133"
                          height="20.533"
                          viewBox="0 0 20.133 20.533"
                        >
                          <path
                            id="ios-checkmark-circle"
                            d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
                            transform="translate(-3.375 -3.375)"
                            fill="#e71134"
                          />
                        </svg>
                        <p className="p-color p-size">Detailed Analysis</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <img src="https://ssccglpinnacle.com/images/Group 36.png" />
                    </Col>
                  </Row>
                  <button
                    className="unlock-button unlock-button-padding"
                    id="buytest"
                    onClick={RegisteredStd == "0" ? handleShow : BuyFun}
                  >
                    Unlock all exams
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <div className="mobilebody">
          <p
            style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Continue to Attempt
          </p>
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            Boost your exam preparation with us
          </p>
          <input
            type="number"
            placeholder="Enter Mobile Number"
            className="entermobile"
            onChange={MobileFun}
          />
        </div>
        <Button
          onClick={
            mobile.length < 10 || mobile.length > 12 ? ValidationFun : handleOtp
          }
          className="continue-btn"
        >
          Continue
        </Button>
      </Modal>

      <Modal show={otpshow}>
        <div className="mobilebody">
          <p
            style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Enter OTP
          </p>
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            Please enter the OTP sent to {mobile}
          </p>
          <input
            type="number"
            placeholder="Enter OTP"
            className="entermobile"
            onChange={OtpFun}
          />
        </div>
        <Button onClick={handleEmail} className="continue-btn">
          Submit OTP
        </Button>
      </Modal>

      <Modal show={showEmail}>
        <div className="mobilebody">
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "4%",
              marginBottom: "1%",
            }}
          >
            Enter Your Name
          </p>
          <input
            type="text"
            placeholder="Enter Name"
            className="entermobile"
            onChange={(e) => setFullName(e.target.value)}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "4%",
              marginBottom: "1%",
            }}
          >
            Enter Email ID
          </p>
          <input
            type="text"
            placeholder="Enter Email ID"
            className="entermobile"
            onChange={(e) => setEmailID(e.target.value)}
          />
        </div>
        <Button onClick={handleEmailSubmit} className="continue-btn">
          Continue
        </Button>
      </Modal>

      <TestSeriesFooter />
    </>
  );
}
export default CategoryExam;

// import "./Header1.css";
// import "./TestSeries.css";
// import { Row, Col, Modal, Button } from "react-bootstrap";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import TestTimer from "./TestTimer";
// import { FaArrowRight } from "react-icons/fa";
// import TestSeriesHeader from "./TestSeriesHeader";
// import TestSeriesFooter from "./TestSeriesFooter";
// import { useCookies } from "react-cookie";

// function CategoryExam() {
//   const [cookies, setCookie] = useCookies();
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [ExamPostData, setExamPostData] = useState([]);
//   const [ExamPostLogoData, setExamPostLogoData] = useState([]);
//   const [ExamPostTierIdData, setExamPostTierIdData] = useState([]);
//   const { id } = useParams();
//   const [freeTestData, setFreeTestData] = useState([]);
//   const [FreeTestDetailsData, setFreeTestDetailsData] = useState([]);
//   const [RelatedTestDataWeb, setRelatedTestDataWeb] = useState([]);
//   const [ExamPostId, setExamPostId] = useState([]);
//   const [LiveTestData, setLiveTestData] = useState([]);
//   const [UpcommingTestData, seUpcommingTestData] = useState([]);
//   const [ExpiredTestData, setExpiredTestData] = useState([]);
//   const [mobile, setMobile] = useState("");
//   const [Otp, setOtp] = useState();
//   const [EnterOtp, setEnterOtp] = useState();
//   const [TestType, setTestType] = useState("Register");
//   const [EmailID, setEmailID] = useState();
//   const [FullName, setFullName] = useState();
//   const [TestSeriesId, setTestSeriesId] = useState();
//   const [ExamModeId, setExamModeId] = useState();
//   const [paper_code, setPaperCode] = useState();
//   const [RegisteredStd, setRegisteredStd] = useState();
//   const [RegisteredUser, setRegisteredUser] = useState();
//   const [RegisteredType, setRegisteredType] = useState("Register");
//   const [UpcommingRegistered, setUpcommingRegistered] = useState("Register");
//   const [TestAccess, setTestAccess] = useState();
//   const [show, setShow] = useState(false);
//   const [otpshow, setOtpShow] = useState(false);
//   const [showEmail, setEmailShow] = useState(false);
//   const [AttemptedTests, setAttemptedTests] = useState([]);
//   const [PCode, setPCode] = useState();
//   const [RegisterData, setRegisterData] = useState();
//   const [TestTitle, setTestTitle] = useState();

//   var url = window.location.href;
//   var user = url.split("?UserID=");
//   var userids;
//   var userids = user != undefined ? (userids = user["1"]) : "";
//   var useridss;
//   useridss = userids != undefined ? userids.split("&CampaignID=") : "";
//   var userid = useridss["0"];
//   var useridsss = useridss["1"];
//   var campaign;
//   var campaign = useridsss != undefined ? useridsss.split("&BannerID=") : "";
//   var campaignid = campaign["0"];
//   var bannerid = campaign["1"];

//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   };

//   useEffect(async () => {
//     let exam_details_request = [{ email_id: cookies.email_id, exam_id: id }];
//     let exam_info = await fetch("/examWebApi", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(exam_details_request),
//     });
//     let exam_infodssdfsd = await exam_info.json();
//     setData(exam_infodssdfsd);
//     setExamPostTierIdData(
//       exam_infodssdfsd[0]["exam_post"][0]["exam_post_tier_id"]
//     );
//     setExamPostData(exam_infodssdfsd[0]["exam_post"]);
//     setExamPostLogoData(exam_infodssdfsd[0]["logo"]);
//   }, []);

//   useEffect(async () => {
//     let related_test_data_web = [{ email_id: cookies.email_id, exam_id: id }];
//     let related_test_data_web_request = await fetch("/RelatedExam", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(related_test_data_web),
//     });
//     let related_test_data_web_response =
//       await related_test_data_web_request.json();
//     setRelatedTestDataWeb(related_test_data_web_response);
//     if (related_test_data_web_response != "") {
//       setExamPostId(related_test_data_web_response[0]["exam_post_tier_id"]);
//     }
//   }, []);

//   useEffect(async () => {
//     let free_test_data = [{ email_id: cookies.email_id, exam_id: id }];
//     let free_test_data_request = await fetch("/FreeTestsWeb", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(free_test_data),
//     });
//     let free_test_data_response = await free_test_data_request.json();
//     setFreeTestData(free_test_data_response);
//     setFreeTestDetailsData(free_test_data_response[0]["details"]);
//   }, []);

//   useEffect(async () => {
//     let test_access = [{ email_id: cookies.email_id }];
//     let test_access_request = await fetch("/testSeriesAccess", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(test_access),
//     });
//     let test_access_response = await test_access_request.json();
//     setTestAccess(test_access_response[0]["user_status"]);
//   }, []);

//   useEffect(() => {
//     clearTimer();
//   }, []);

//   async function postTier(e) {
//     let tier_id = e.target.dataset.tier;
//     navigate("/TestSeriesSingle/" + tier_id);
//   }

//   async function viewAll(e) {
//     let tier_id = e.target.dataset.tier;
//     navigate("/TestSeriesSingle/" + tier_id);
//   }

//   async function clearTimer(e) {
//     let live_test_email_id = [{ email_id: cookies.email_id }];
//     let live_test_request = await fetch("/MegaMockChallengeTest", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(live_test_email_id),
//     });
//     let live_test_response = await live_test_request.json();
//     setRegisteredStd(live_test_response[0]["registered_std"]);
//     if (live_test_response[0]["LiveTests"] != "") {
//       setLiveTestData(live_test_response[0]["LiveTests"]);
//       setTestSeriesId(live_test_response[0]["LiveTests"][0]["test_series_id"]);
//       setExamModeId(live_test_response[0]["LiveTests"][0]["exam_mode_id"]);
//       setPaperCode(live_test_response[0]["LiveTests"][0]["paper_code"]);
//       if (live_test_response[0]["LiveTests"][0]["left_days"] == "live") {
//         setTestType("Start Test");
//       }
//       seUpcommingTestData(live_test_response[0]["UpcommingTests"]);
//       setExpiredTestData(live_test_response[0]["ExpiredTests"]);
//       setAttemptedTests(live_test_response[0]["AttemptedTests"]);
//       setRegisteredStd(live_test_response[0]["LiveTests"][0]["registered_std"]);
//       setRegisteredUser(
//         live_test_response[0]["LiveTests"][0]["registered_user"]
//       );
//     }
//   }

//   const handleUser = async (e) => {
//     let paper_code = e.target.value;
//     let std_details = [
//       {
//         mobile,
//         Otp,
//         EmailID: cookies.email_id,
//         test_series_id: TestSeriesId,
//         exam_mode_id: ExamModeId,
//         paper_code,
//       },
//     ];
//     await fetch("/studentInfo", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(std_details),
//     });
//     setRegisteredType("Registered");
//   };

//   const handleUpcomingUser = async (e) => {
//     let paper_code = e.target.value;
//     let std_details = [
//       {
//         mobile,
//         Otp,
//         EmailID: cookies.email_id,
//         test_series_id: TestSeriesId,
//         exam_mode_id: ExamModeId,
//         paper_code,
//       },
//     ];
//     await fetch("/studentInfo", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(std_details),
//     });
//     setPCode(paper_code);
//     setUpcommingRegistered("Registered");
//   };

//   const [BuyTest, setBuyTest] = useState();

//   const handleClose = () => setShow(false);

//   const handleShow = (e) => {
//     console.log(e.target.value);
//     console.log(e.target.id);
//     console.log(e.target.getAttribute("data-id"));
//     setRegisterData(e.target.value);
//     setTestTitle(e.target.getAttribute("data-id"));
//     setBuyTest(e.target.id);
//     setShow(true);
//   };

//   const handleOtp = async () => {
//     setOtpShow(true);
//     setShow(false);
//     let otp_data = { mobile: mobile, name: "Student" };
//     let otp_request = await fetch("/otp", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(otp_data),
//     });
//     let otp_response = await otp_request.json();
//     setOtp(otp_response.OTP);
//   };

//   const handleEmail = () => {
//     if (EnterOtp == Otp) {
//       setOtpShow(false);
//       setEmailShow(true);
//     } else {
//       alert("Please enter the OTP sent to " + mobile);
//     }
//   };

//   const BuyFun = () => {
//     userid != undefined
//       ? (window.location.href =
//           "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-?UserID=" +
//           userid +
//           "&CampaignID=" +
//           campaignid +
//           "&BannerID=" +
//           bannerid)
//       : (window.location.href =
//           "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-");
//   };

//   const handleEmailSubmit = async () => {
//     let days = 365;
//     let expires = new Date();
//     expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
//     setCookie("email_id", EmailID, {
//       path: "/",
//       expires,
//     });
//     setEmailShow(false);
//     let std_details = [
//       {
//         mobile,
//         Otp,
//         EmailID: EmailID,
//         test_series_id: TestSeriesId,
//         exam_mode_id: ExamModeId,
//         paper_code,
//         FullName,
//       },
//     ];
//     await fetch("/studentInfo", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(std_details),
//     });
//     setPCode(paper_code);
//     setRegisteredType("Registered");
//     //if (BuyTest == 'buytest' && RegisteredStd != '0') {
//     if (BuyTest == "buytest") {
//       window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${EmailID}`;
//     } else {
//       if (RegisterData == 0) {
//         navigate(
//           `/Instruction/${btoa(ExamModeId)}/${btoa(paper_code)}/${btoa(
//             TestSeriesId
//           )}/${btoa(TestTitle)}`
//         );
//       } else {
//         setRegisteredType("Registered");
//       }
//     }
//   };

//   const MobileFun = (e) => {
//     setMobile(e.target.value);
//   };

//   const OtpFun = (e) => {
//     setEnterOtp(e.target.value);
//   };

//   const setTest = (tst) => {
//     setTestType(tst);
//   };

//   const startTest = async (event) => {
//     let live_test_paper = [{ paper_code: paper_code }];
//     let live_test_request = await fetch("/LiveTestCount", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
//       },
//       body: JSON.stringify(live_test_paper),
//     });
//     await live_test_request.json();

//     let test_title = event.target.getAttribute("test_title");
//     navigate(
//       `/Instruction/${btoa(ExamModeId)}/${btoa(paper_code)}/${btoa(
//         TestSeriesId
//       )}/${btoa(test_title)}`
//     );
//   };

//   const start = (event) => {
//     let exam_mode_id = event.target.getAttribute("exam_mode_id");
//     let test_series_id = event.target.getAttribute("test_series_id");
//     let papercode = event.target.getAttribute("paper_code");
//     let test_title = event.target.getAttribute("title");
//     navigate(
//       `/Instruction/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
//         test_series_id
//       )}/${btoa(test_title)}`
//     );
//   };

//   const HomeFun = () => {
//     window.location.href = "https://ssccglpinnacle.com";
//   };

//   const TestSeriesFun = () => {
//     if (cookies.email_id == undefined || cookies.email_id == "undefined") {
//       navigate(`/testseries`);
//     } else {
//       navigate(`/testseries/${cookies.email_id}`);
//     }
//   };

//   const ViewResultFun = (event) => {
//     let exam_mode_id = event.target.getAttribute("exam_mode_id");
//     let test_series_id = event.target.getAttribute("test_series_id");
//     let paper_code = event.target.getAttribute("paper_code");
//     let title = event.target.getAttribute("title");
//     let expir_date = event.target.getAttribute("expired_date");
//     let expir_time = event.target.getAttribute("expired_time");

//     var todayDate = new Date().toISOString().slice(0, 10);

//     if (expir_date < todayDate) {
//       navigate(
//         `/Attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
//           test_series_id
//         )}/${btoa(title)}`
//       );
//     } else {
//       navigate(
//         `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
//           test_series_id
//         )}`
//       );
//     }
//   };

//   const ValidationFun = () => {
//     alert(`Please Enter Valid Mobile Number`);
//   };

//   return (
//     <>
//       <TestSeriesHeader />
//       <div className="section-first-category-exam-page font-size12 margin-top10 margin-bottom10">
//         <div className="test-pass-container">
//           <ul className="breadcrumb">
//             <li
//               className="secondary"
//               onClick={HomeFun}
//               style={{ cursor: "pointer" }}
//             >
//               Home
//             </li>
//             <li
//               className="secondary"
//               onClick={TestSeriesFun}
//               style={{ cursor: "pointer" }}
//             >
//               Test Series
//             </li>
//             <li className="gray">
//               {id == 1 ? "SSC" : id == 2 ? "Delhi Police" : "Railway"}
//             </li>
//           </ul>
//           <h2 className="ssc-test-series-heading">
//             SSC Yearly Test Series 2022-2023
//           </h2>
//           <p className="online-test-series-p">
//             Attempt online test series for upcoming SSC exams. Start with a free
//             mock test now Mock Tests I Previous Year TCS Papers I Sectional
//             tests I Chapter wise Tests I Misc. Tests
//           </p>
//           <Row className="margin-upper margin-none">
//             <Col md={8} className="no-padding">
//               <Row className="margin-none">
//                 <Col md={10} className="no-padding">
//                   <h2 className="recommended-heading">Recommended Exams</h2>
//                   <p className="recommended-exam-p">
//                     View test series for upcoming exams : Mock tests I Previous
//                     Year TCS Tests I Sectional tests I Chapter wise I
//                     Challenging Tests
//                   </p>
//                 </Col>
//                 <Col md={2}>
//                   <p
//                     style={{ cursor: "pointer" }}
//                     onClick={viewAll}
//                     data-tier={ExamPostTierIdData}
//                     className="recomended-viewall secondary"
//                   >
//                     View All
//                   </p>
//                 </Col>
//               </Row>
//               <div className="main-div">
//                 <Row className="margin-none">
//                   <Carousel
//                     responsive={responsive}
//                     autoPlay="false"
//                     autoPlaySpeed={100000000}
//                   >
//                     {ExamPostData.map((expm, l) => (
//                       <Col md={12} style={{ padding: "17px" }} key={l}>
//                         <div
//                           className="alltestseries-exam-category-div full-width"
//                           data-tier={expm.exam_post_tier_id}
//                           onClick={postTier}
//                         >
//                           <img
//                             className="exam-category-img margin-bottom20"
//                             data-tier={expm.exam_post_tier_id}
//                             src={ExamPostLogoData}
//                           />
//                           <p
//                             className="exam-category-p line-height16 font-size15-weight-600"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             {expm.exam_post_tier}
//                           </p>
//                           <p
//                             className="exam-category-p line-height16 font-size15"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             Total Test: {expm.test_count}
//                           </p>
//                           <p
//                             className="exam-category-p line-height16 font-size15"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             Mock Test: {expm.full_test_count}
//                           </p>
//                           <p
//                             className="exam-category-p line-height16 font-size15"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             Chapter Test: {expm.chapter_test_count}
//                           </p>
//                           <p
//                             className="exam-category-p line-height16 font-size15"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             Sectional Test: {expm.subject_test_count}
//                           </p>
//                           <p
//                             className="exam-category-p line-height16 font-size15"
//                             data-tier={expm.exam_post_tier_id}
//                           >
//                             PY Test: {expm.previous_test_count}
//                           </p>
//                         </div>
//                       </Col>
//                     ))}
//                   </Carousel>
//                 </Row>
//                 <Row className="margin-none">
//                   {TestAccess == 1 ? (
//                     ""
//                   ) : (
//                     <button
//                       className="unlock-button unlock-button-padding unlock-width"
//                       id="buytest"
//                       onClick={RegisteredStd == "0" ? handleShow : BuyFun}
//                     >
//                       Unlock all exams
//                     </button>
//                   )}
//                 </Row>
//               </div>
//             </Col>
//             <Col md={4}>
//               <div className="bg-background">
//                 <p className="category-yearly-test-pass">
//                   Buy Yearly test pass to unlock all SSC exams tests: 1000+
//                   tests
//                 </p>
//                 <Row className="margin-none">
//                   <Col md={6} className="no-padding padding-left15">
//                     <div className="full-width">
//                       <svg
//                         className="bg-secondary bg-width"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         />
//                       </svg>
//                       <p className="p-color p-size">Latest TCS Pattern</p>
//                     </div>
//                     <div className="full-width">
//                       <svg
//                         className="bg-secondary bg-width"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         />
//                       </svg>
//                       <p className="p-color p-size">
//                         Comprehensive test series
//                       </p>
//                     </div>
//                     <div className="full-width">
//                       <svg
//                         className="bg-secondary bg-width"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         />
//                       </svg>
//                       <p className="p-color p-size">Exam Oriented Solution</p>
//                     </div>
//                     <div className="full-width">
//                       <svg
//                         className="bg-secondary bg-width"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         />
//                       </svg>
//                       <p className="p-color p-size">Detailed Analysis</p>
//                     </div>
//                   </Col>
//                   <Col md={6}>
//                     <img src="https://ssccglpinnacle.com/images/Group 36.png" />
//                   </Col>
//                 </Row>
//                 {TestAccess == 1 ? (
//                   ""
//                 ) : (
//                   <button
//                     className="unlock-button unlock-button-padding"
//                     id="buytest"
//                     onClick={RegisteredStd == "0" ? handleShow : BuyFun}
//                   >
//                     Unlock all exams
//                   </button>
//                 )}
//               </div>
//             </Col>
//           </Row>

//           <div className="margin-upper full-width">
//             <h2 className="recommended-heading">
//               Attempt Free SSC Exams Online Tests
//             </h2>
//             <p className="recommended-exam-p">
//               Start your Free online tests here
//             </p>
//             <Row>
//               <Carousel
//                 responsive={responsive}
//                 autoPlay="false"
//                 autoPlaySpeed={100000000}
//               >
//                 {FreeTestDetailsData.map((fdm, x) => (
//                   <Col md={11} style={{ padding: "17px" }} key={x}>
//                     <div className="alltestseries-exam-category-div full-width div-padding">
//                       <h6 className="left-algn full-width">{fdm.Title}</h6>
//                       {/*  <p className="left-algn full-width">Expires on {fdm.expire_date}</p> */}
//                       <p>{fdm.test_type}</p>
//                       <div className="bottom-line"></div>
//                       <div className="left-right full-width">
//                         <span>Questions</span>
//                         <span>{fdm.Questions}</span>
//                       </div>
//                       <div className="left-right full-width">
//                         <span>Time(mins)</span>
//                         <span>{fdm.Time}</span>
//                       </div>
//                       <div className="left-right full-width">
//                         <span>Maximum Marks</span>
//                         <span>{fdm.Marks}</span>
//                       </div>
//                       <div className="bottom-line"> </div>
//                       <div
//                         onClick={start}
//                         test_series_id={fdm.test_series_id}
//                         exam_mode_id={fdm.exam_mode_id}
//                         paper_code={fdm.paper_code}
//                         title={fdm.Title}
//                         className="full-width attempt"
//                       >
//                         <span
//                           test_series_id={fdm.test_series_id}
//                           exam_mode_id={fdm.exam_mode_id}
//                           paper_code={fdm.paper_code}
//                           title={fdm.Title}
//                         >
//                           Attempt
//                         </span>
//                         <span
//                           test_series_id={fdm.test_series_id}
//                           exam_mode_id={fdm.exam_mode_id}
//                           paper_code={fdm.paper_code}
//                           title={fdm.Title}
//                           style={{ fontSize: "12px" }}
//                         >
//                           <FaArrowRight />
//                         </span>
//                       </div>
//                     </div>
//                   </Col>
//                 ))}
//               </Carousel>
//             </Row>
//           </div>
//         </div>
//       </div>
//       <div className="bg-skyblue full-width div-padding">
//         <div className="test-pass-container">
//           <Row className="margin-none">
//             <Col md={6} className="center-align">
//               <Row className="margin-none">
//                 <Col md={12}>
//                   <img src="https://grdp.co/cdn-cgi/image/width=176,height=156,quality=100,f=auto/https://gs-post-images.grdp.co/2021/8/group-4-2x-img1629278264534-38.png-rs-high-webp.png" />
//                   <p>Aaj ka Champion kaun</p>
//                   <p>All India Live Mock Test</p>
//                 </Col>
//                 <Col md={12}>
//                   <Row className="margin-none">
//                     <Col
//                       md={3}
//                       className="margin-center right-border center-align fnt13 padding-left15"
//                     >
//                       <svg
//                         className="bg-secondary bg-width center-align margin-center fln"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         ></path>
//                       </svg>
//                       <span className="full-width center-align">
//                         Latest TCS Pattern
//                       </span>
//                     </Col>
//                     <Col
//                       md={3}
//                       className="margin-center right-border center-align fnt13"
//                     >
//                       <svg
//                         className="bg-secondary bg-width center-align margin-center fln"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         ></path>
//                       </svg>
//                       <span className="full-width center-align">
//                         Real Exam exposure
//                       </span>
//                     </Col>
//                     <Col
//                       md={3}
//                       className="margin-center right-border center-align fnt13"
//                     >
//                       <svg
//                         className="bg-secondary bg-width center-align margin-center fln"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         ></path>
//                       </svg>
//                       <span className="full-width center-align">
//                         Compete with thousands of students
//                       </span>
//                     </Col>
//                     <Col md={3} className="margin-center center-align fnt13">
//                       <svg
//                         className="bg-secondary bg-width center-align margin-center fln"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="20.133"
//                         height="20.533"
//                         viewBox="0 0 20.133 20.533"
//                       >
//                         <path
//                           id="ios-checkmark-circle"
//                           d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                           transform="translate(-3.375 -3.375)"
//                           fill="#e71134"
//                         ></path>
//                       </svg>
//                       <span className="full-width center-align">
//                         Detailed performance analysis
//                       </span>
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>
//             </Col>
//             <Col md={6}>
//               {LiveTestData.map((livetst, l) => (
//                 <>
//                   <div
//                     className="alltestseries-exam-category-div full-width margin-center div-padding wd80 ml10"
//                     key={l}
//                   >
//                     <img
//                       className="exam-category-img"
//                       src="https://ssccglpinnacle.com/images/ssc-logo.png"
//                     />
//                     <h5 className="center-align full-width">
//                       {livetst.test_title}
//                     </h5>
//                     <Row className="full-width">
//                       <Col md={4} className="no-padding">
//                         <p className="right-border">
//                           {livetst.TotalQuestions} Questions
//                         </p>
//                       </Col>
//                       <Col md={4} className="no-padding">
//                         <p className="right-border">
//                           {livetst.TotalMarks} Marks
//                         </p>
//                       </Col>
//                       <Col md={4}>
//                         <p className="right-border">
//                           {livetst.TotalTime} Minutes
//                         </p>
//                       </Col>
//                     </Row>
//                     <Row className="full-width margin-left0 margin-right0 rb3">
//                       {TestType == "Start Test" ||
//                       livetst.left_days == "live" ? (
//                         <Col md={12} className="right-border liveclass">
//                           <p className="margin-bottom0">Test is Live now</p>
//                         </Col>
//                       ) : RegisteredStd == "1" && RegisteredUser == "1" ? (
//                         <Col md={12} className="right-border">
//                           <p
//                             className="margin-bottom0"
//                             style={{ fontSize: "13px", marginTop: "2%" }}
//                           >
//                             Starts In
//                           </p>
//                           <p style={{ fontWeight: "600" }}>
//                             {livetst.left_days == "24" ? (
//                               <TestTimer
//                                 hours={livetst.hours}
//                                 mins={livetst.minutes}
//                                 secs={livetst.seconds}
//                                 setTest={setTest}
//                               />
//                             ) : livetst.left_days == "live" ? (
//                               "Live"
//                             ) : (
//                               livetst.left_days
//                             )}
//                           </p>
//                         </Col>
//                       ) : (
//                         <>
//                           <Col md={6} className="right-border">
//                             <p className="margin-bottom0">Starts On</p>
//                             <p>{livetst.start_date}</p>
//                           </Col>
//                           <Col md={6}>
//                             <p style={{ margin: "0" }}>Time Left</p>
//                             <p>
//                               {livetst.left_days == "24" ? (
//                                 <TestTimer
//                                   hours={livetst.hours}
//                                   mins={livetst.minutes}
//                                   secs={livetst.seconds}
//                                   setTest={setTest}
//                                 />
//                               ) : livetst.left_days == "live" ? (
//                                 "Live"
//                               ) : (
//                                 livetst.left_days + " to go"
//                               )}
//                             </p>
//                           </Col>
//                         </>
//                       )}
//                     </Row>
//                     <div className="full-width">
//                       {livetst.left_days == "live" && RegisteredStd == "1" ? (
//                         <button
//                           className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
//                           test_title={livetst.test_title}
//                           onClick={startTest}
//                         >
//                           Start Test
//                         </button>
//                       ) : RegisteredStd == "1" && RegisteredUser == "1" ? (
//                         <button className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt reg-user">
//                           Registered
//                         </button>
//                       ) : RegisteredStd == "1" && RegisteredUser == "0" ? (
//                         <button
//                           className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
//                           value={livetst.paper_code}
//                           onClick={handleUser}
//                         >
//                           {RegisteredType}
//                         </button>
//                       ) : (
//                         <button
//                           className="unlock-button unlock-button-padding full-width width100 no-padding no-mrlt"
//                           value={RegisteredStd}
//                           data-id={livetst.test_title}
//                           onClick={handleShow}
//                         >
//                           {RegisteredType}
//                         </button>
//                       )}
//                     </div>
//                   </div>
//                 </>
//               ))}
//             </Col>
//           </Row>
//         </div>
//       </div>

//       {UpcommingTestData == "" ? (
//         ""
//       ) : (
//         <div className="section-first-category-exam-page">
//           <div className="test-pass-container">
//             <div className="margin-upper full-width">
//               <h2 className="recommended-heading">
//                 Upcoming Mega Mock Challenge
//               </h2>
//               <div className="main-div">
//                 <div className="scroller-button top34-percent transform0">
//                   <svg
//                     id="arrow-ios-forward-outline"
//                     width="10.589"
//                     height="21.047"
//                     viewBox="0 0 10.589 21.047"
//                   >
//                     <path
//                       id="arrow-ios-forward-outline-2"
//                       data-name="arrow-ios-forward-outline"
//                       d="M15,28.5a1.5,1.5,0,0,1-1.155-2.46L20.565,18l-6.48-8.055A1.533,1.533,0,1,1,16.5,8.055l7.245,9a1.5,1.5,0,0,1,0,1.905l-7.5,9A1.5,1.5,0,0,1,15,28.5Z"
//                       transform="translate(-13.497 -7.456)"
//                       fill="#ed5f69"
//                     ></path>
//                   </svg>
//                 </div>
//                 <Row className="main-div-scroller" style={{ width: "100%" }}>
//                   {UpcommingTestData &&
//                     UpcommingTestData.map((fdm, x) => (
//                       <>
//                         <Col md={4} key={x}>
//                           <div className="alltestseries-exam-category-div full-width div-padding">
//                             <h6
//                               className="left-algn full-width"
//                               style={{ fontWeight: "600" }}
//                             >
//                               {fdm.test_title}
//                             </h6>
//                             <p
//                               className="left-algn full-width"
//                               style={{ fontSize: "14px" }}
//                             >
//                               Starts on {fdm.start_date}
//                             </p>
//                             <div className="left-right full-width">
//                               <span>8800+ users have already registered</span>
//                             </div>
//                             <div className="bottom-line"> </div>
//                             {fdm.registered_std == "1" &&
//                             fdm.registered_user == "1" ? (
//                               <div className="full-width attempt">
//                                 <span>Registered</span>
//                               </div>
//                             ) : fdm.registered_std == "1" &&
//                               fdm.registered_user == "0" ? (
//                               <button
//                                 value={fdm.paper_code}
//                                 onClick={handleUpcomingUser}
//                                 className="full-width attempt"
//                               >
//                                 {fdm.paper_code == PCode
//                                   ? UpcommingRegistered
//                                   : "Register"}
//                                 <span
//                                   style={{ fontSize: "12px" }}
//                                   value={fdm.paper_code}
//                                 >
//                                   <FaArrowRight />
//                                 </span>
//                               </button>
//                             ) : (
//                               <div
//                                 className="full-width attempt"
//                                 onClick={handleShow}
//                               >
//                                 <span>{RegisteredType}</span>
//                               </div>
//                             )}
//                           </div>
//                         </Col>
//                       </>
//                     ))}
//                 </Row>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {ExpiredTestData == "" ? (
//         ""
//       ) : (
//         <div className="section-first-category-exam-page">
//           <div className="test-pass-container">
//             <div className="margin-upper full-width">
//               <h2 className="recommended-heading">
//                 Expired Mega Mock Challenge
//               </h2>
//               <Row className="main-div-scroller" style={{ width: "100%" }}>
//                 <Carousel
//                   responsive={responsive}
//                   autoPlay="false"
//                   autoPlaySpeed={100000000}
//                 >
//                   {ExpiredTestData &&
//                     ExpiredTestData.map((exp, ex) => (
//                       <Col md={12} style={{ padding: "17px" }} key={ex}>
//                         <div className="alltestseries-exam-category-div full-width div-padding">
//                           <h6
//                             className="left-algn full-width"
//                             style={{ fontWeight: "600" }}
//                           >
//                             {exp.test_title}
//                           </h6>
//                           <p
//                             className="left-algn full-width"
//                             style={{ fontSize: "14px" }}
//                           >
//                             Expired on {exp.expiry_date}
//                           </p>
//                           <div className="left-right full-width">
//                             <span>8800+ users have already registered</span>
//                           </div>
//                           <div className="bottom-line"> </div>
//                           <div className="full-width attempt">
//                             <span style={{ color: "gray", fontWeight: "600" }}>
//                               Expired
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                     ))}
//                 </Carousel>
//               </Row>
//             </div>
//           </div>
//         </div>
//       )}

//       {AttemptedTests == "" ? (
//         ""
//       ) : (
//         <div className="section-first-category-exam-page">
//           <div className="test-pass-container">
//             <div className="margin-upper full-width">
//               <h2 className="recommended-heading">
//                 Attempted Mega Mock Challenge
//               </h2>
//               <Row className="main-div-scroller" style={{ width: "100%" }}>
//                 <Carousel
//                   responsive={responsive}
//                   autoPlay="false"
//                   autoPlaySpeed={100000000}
//                 >
//                   {AttemptedTests &&
//                     AttemptedTests.map((atmp, at) => (
//                       <Col md={12} style={{ padding: "17px" }} key={at}>
//                         <div className="alltestseries-exam-category-div full-width div-padding">
//                           <h6
//                             className="left-algn full-width"
//                             style={{ fontWeight: "600" }}
//                           >
//                             {atmp.test_title}
//                           </h6>
//                           <p
//                             className="left-algn full-width"
//                             style={{ fontSize: "14px" }}
//                           >
//                             Attempted on {atmp.attempted_date}
//                           </p>
//                           <div className="left-right full-width">
//                             <span>8800+ users have already registered</span>
//                           </div>
//                           <div className="bottom-line"> </div>
//                           <div className="full-width attempt">
//                             <span
//                               style={{ color: "orange", fontWeight: "600" }}
//                               onClick={ViewResultFun}
//                               test_series_id={atmp.test_series_id}
//                               exam_mode_id={atmp.exam_mode_id}
//                               paper_code={atmp.paper_code}
//                               title={atmp.test_title}
//                               expired_date={atmp.expiry_date}
//                               expired_time={atmp.expiry_time}
//                             >
//                               View Result
//                             </span>
//                           </div>
//                         </div>
//                       </Col>
//                     ))}
//                 </Carousel>
//               </Row>
//             </div>
//           </div>
//         </div>
//       )}

//       <div className="full-width mt55 margin-bottom30">
//         <div className="test-pass-container">
//           <Row className="margin-none">
//             <Col md={8} className="no-padding">
//               {RelatedTestDataWeb == ""
//                 ? ""
//                 : RelatedTestDataWeb.map((rtdm, o) => (
//                     <>
//                       <Row className="margin-none">
//                         <Col md={10} className="no-padding">
//                           <h2 className="recommended-heading">Other Exams</h2>
//                           <p className="recommended-exam-p">
//                             View Test series of other exams in the test pass
//                           </p>
//                         </Col>
//                         <Col md={2}>
//                           <p
//                             onClick={viewAll}
//                             data-tier={ExamPostId}
//                             style={{ cursor: "pointer" }}
//                             className="recomended-viewall secondary"
//                           >
//                             View All
//                           </p>
//                         </Col>
//                       </Row>
//                       <div className="main-div">
//                         <Row className="margin-none">
//                           <Col md={4} key={o}>
//                             <div
//                               className="alltestseries-exam-category-div full-width"
//                               data-tier={rtdm.exam_post_tier_id}
//                               onClick={postTier}
//                             >
//                               <img
//                                 className="exam-category-img"
//                                 data-tier={rtdm.exam_post_tier_id}
//                                 src={rtdm.logo}
//                               />
//                               <p
//                                 className="exam-category-p"
//                                 data-tier={rtdm.exam_post_tier_id}
//                               >
//                                 {rtdm.post_name}
//                               </p>
//                             </div>
//                           </Col>
//                         </Row>
//                       </div>
//                     </>
//                   ))}
//               <Row className="margin-none">
//                 {TestAccess == 1 ? (
//                   ""
//                 ) : (
//                   <button
//                     className="unlock-button unlock-button-padding unlock-width"
//                     id="buytest"
//                     onClick={RegisteredStd == "0" ? handleShow : BuyFun}
//                   >
//                     Unlock all exams
//                   </button>
//                 )}
//               </Row>
//             </Col>
//             {TestAccess == 1 ? (
//               ""
//             ) : (
//               <Col md={4}>
//                 <div className="bg-background">
//                   <p className="category-yearly-test-pass">
//                     Buy Yearly test pass to unlock all SSC exams tests: 1000+
//                     tests
//                   </p>
//                   <Row className="margin-none">
//                     <Col md={6} className="no-padding padding-left15">
//                       <div className="full-width">
//                         <svg
//                           className="bg-secondary bg-width"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="20.133"
//                           height="20.533"
//                           viewBox="0 0 20.133 20.533"
//                         >
//                           <path
//                             id="ios-checkmark-circle"
//                             d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                             transform="translate(-3.375 -3.375)"
//                             fill="#e71134"
//                           />
//                         </svg>
//                         <p className="p-color p-size">Latest TCS Pattern</p>
//                       </div>
//                       <div className="full-width">
//                         <svg
//                           className="bg-secondary bg-width"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="20.133"
//                           height="20.533"
//                           viewBox="0 0 20.133 20.533"
//                         >
//                           <path
//                             id="ios-checkmark-circle"
//                             d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                             transform="translate(-3.375 -3.375)"
//                             fill="#e71134"
//                           />
//                         </svg>
//                         <p className="p-color p-size">
//                           Comprehensive test series
//                         </p>
//                       </div>
//                       <div className="full-width">
//                         <svg
//                           className="bg-secondary bg-width"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="20.133"
//                           height="20.533"
//                           viewBox="0 0 20.133 20.533"
//                         >
//                           <path
//                             id="ios-checkmark-circle"
//                             d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                             transform="translate(-3.375 -3.375)"
//                             fill="#e71134"
//                           />
//                         </svg>
//                         <p className="p-color p-size">Exam Oriented Solution</p>
//                       </div>
//                       <div className="full-width">
//                         <svg
//                           className="bg-secondary bg-width"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="20.133"
//                           height="20.533"
//                           viewBox="0 0 20.133 20.533"
//                         >
//                           <path
//                             id="ios-checkmark-circle"
//                             d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z"
//                             transform="translate(-3.375 -3.375)"
//                             fill="#e71134"
//                           />
//                         </svg>
//                         <p className="p-color p-size">Detailed Analysis</p>
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <img src="https://ssccglpinnacle.com/images/Group 36.png" />
//                     </Col>
//                   </Row>
//                   <button
//                     className="unlock-button unlock-button-padding"
//                     id="buytest"
//                     onClick={RegisteredStd == "0" ? handleShow : BuyFun}
//                   >
//                     Unlock all exams
//                   </button>
//                 </div>
//               </Col>
//             )}
//           </Row>
//         </div>
//       </div>

//       <Modal show={show} onHide={handleClose}>
//         <div className="mobilebody">
//           <p
//             style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
//           >
//             Continue to Attempt
//           </p>
//           <p style={{ textAlign: "center", fontSize: "13px" }}>
//             Boost your exam preparation with us
//           </p>
//           <input
//             type="number"
//             placeholder="Enter Mobile Number"
//             className="entermobile"
//             onChange={MobileFun}
//           />
//         </div>
//         <Button
//           onClick={
//             mobile.length < 10 || mobile.length > 12 ? ValidationFun : handleOtp
//           }
//           className="continue-btn"
//         >
//           Continue
//         </Button>
//       </Modal>

//       <Modal show={otpshow}>
//         <div className="mobilebody">
//           <p
//             style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
//           >
//             Enter OTP
//           </p>
//           <p style={{ textAlign: "center", fontSize: "13px" }}>
//             Please enter the OTP sent to {mobile}
//           </p>
//           <input
//             type="number"
//             placeholder="Enter OTP"
//             className="entermobile"
//             onChange={OtpFun}
//           />
//         </div>
//         <Button onClick={handleEmail} className="continue-btn">
//           Submit OTP
//         </Button>
//       </Modal>

//       <Modal show={showEmail}>
//         <div className="mobilebody">
//           <p
//             style={{
//               textAlign: "center",
//               fontSize: "18px",
//               fontWeight: "600",
//               marginTop: "4%",
//               marginBottom: "1%",
//             }}
//           >
//             Enter Your Name
//           </p>
//           <input
//             type="text"
//             placeholder="Enter Name"
//             className="entermobile"
//             onChange={(e) => setFullName(e.target.value)}
//           />
//           <p
//             style={{
//               textAlign: "center",
//               fontSize: "18px",
//               fontWeight: "600",
//               marginTop: "4%",
//               marginBottom: "1%",
//             }}
//           >
//             Enter Email ID
//           </p>
//           <input
//             type="text"
//             placeholder="Enter Email ID"
//             className="entermobile"
//             onChange={(e) => setEmailID(e.target.value)}
//           />
//         </div>
//         <Button onClick={handleEmailSubmit} className="continue-btn">
//           Continue
//         </Button>
//       </Modal>

//       <TestSeriesFooter />
//     </>
//   );
// }
// export default CategoryExam;
