import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { BiChevronDown } from 'react-icons/bi';

function Accordion({ title, content }){
  const [isActive, setIsActive] = useState(false);
    
  return (         
    <Col md={6} className="margin-bottom30">
    <div className="accordion-item border-none">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="float-left width90-perc font-size17">{title}</div>
        <div className="right5-perc position-absolute font-size17">{isActive ? <BiChevronDown /> : '+'}</div>
      </div>
      {isActive && <div className="accordion-content background-color-f3f7ff padding-24px-20px-24px-20px">{content}</div>}
    </div>                             
    </Col>  
  );
};
           
export default Accordion;                                    