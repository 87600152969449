import { Row, Col, Container, Button } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import TypingTimer from "./TypingTimer";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import "./Typing.css";
import { useCookies } from "react-cookie";

const TypingModule = () => {
  const { testcode, exam, category } = useParams();
  const [message, setMessage] = useState("");
  const [wpm, setWpm] = useState("");
  const [minute, setMinute] = useState("");
  const hoursMinSecs = { hours: 0, minutes: minute, seconds: 0 };
  const [rmTm, setrmTm] = useState();
  const navigate = useNavigate();
  const [paragraph, setParagraph] = useState("");
  const [typing, setTyping] = useState(false);
  const [contentLength, setContentLength] = useState("");
  const [cookies] = useCookies();

  useEffect(async () => {
    let dt = { paper_code: testcode, exam: exam };
    let state_res = await fetch("/typingParagraph", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(dt),
    });
    state_res = await state_res.json();
    setParagraph(state_res.paragraph);
    setContentLength(state_res.paragraph.length);
    setMinute(state_res.time);
  }, []);

  let d = paragraph;

  const handleMessageChange = (event) => {
    if (typing == false) {
      setTyping(true);
    }
    setMessage(event.target.value);
    console.log(event.target.value);
  };

  const rmTimeFun = (rTm) => {
    setrmTm(rTm);
  };

  const messageSubmit = async () => {
    let s;

    s = message;

    var actualdep = Math.round(s.length);
    var totaldep = d.length;

    //console.log(actualdep)
    //console.log(totaldep)
    s = s.split(" ");

    d = d.split(" ");

    let c = 0;
    var iac = 0;
    var ic = 0;
    var dd = 0;

    let scale = [];

    for (var i = 0; i < d.length; i++) {
      console.log(s[i]);
      // console.log(d)
      if (s.length > i) {
        //document.writeln(c)
        if (s[i] == d[i]) {
          var a = s[i];
          //var b = parseInt(a.length) + 1;
          //c += b;
          //document.writeln(c)
          //document.writeln(s[i])
          //  console.log(s)
          let start = i;
          let deleteCount = 0;
          scale.splice(start, deleteCount, d[i]);
          c += s[i].length + 1;
        } else if (s[i] == "") {
          // console.log(s)
          let start = i;
          let deleteCount = 0;
          s.splice(start, deleteCount, d[i]);
          scale.splice(
            start,
            deleteCount,
            "<span class='wrongword'>" + d[i] + "</span>"
          );
        } else {
          //s[i] = d[i]

          //console.log(s)

          var ii = i + 1;
          var iaa = s[i] + s[ii];

          var daa = d[i] + d[ii];

          var daaa = d[i] + d[ii + 1];

          if (iaa == d[i]) {
            var iacc = s[i].length + 1;
            iac = iacc / 2;
            c += iac;
            //document.writeln(iac)
            let start = i;
            let deleteCount = 0;
            scale.splice(
              start,
              deleteCount,
              d[i] + "<span class='wrongword'>" + s[i] + "</span>"
            );
            s.splice(start, 2);
            //  console.log(s)

            s.splice(start, deleteCount, d[i]);
          } else if (iaa == daa) {
            var iacc = s[i].length + 1;
            iac = iacc / 2;
            c += iac;
            //document.writeln(iac)
            //  console.log(s)
            let start = i;
            let deleteCount = 0;
            scale.splice(
              start,
              deleteCount,
              d[i] + "<span class='wrongword'>" + s[i] + "</span>"
            );
            s.splice(start, deleteCount, d[i]);
          } else if (iaa == daaa) {
            var iacc = s[i].length + 1;
            iac = iacc / 2;
            c += iac;
            //document.writeln(iac)
            //  console.log(s)
            let start = i;
            let deleteCount = 0;
            scale.splice(
              start,
              deleteCount,
              d[i] + "<span class='wrongword'>" + s[i] + "</span>"
            );
            s.splice(start, deleteCount, d[i]);
          } else {
            if (i > 0 && i < s.length) {
              var mdaa = d[i - 1];
              //document.writeln(mdaa)
              if (i > 0 && i < d.length) {
                if (s[i].toLowerCase() == d[i].toLowerCase()) {
                  let start = i;
                  let deleteCount = 0;
                  scale.splice(
                    start,
                    deleteCount,
                    d[i] + "<span class='wrongword'>" + s[i] + "</span>"
                  );
                  var iacc = s[i].length + 1;
                  iac = iacc / 2;
                  c += iac;
                } else {
                  if (
                    d[i] != s[i] &&
                    d[i + 1] != undefined &&
                    d[i + 1].toLowerCase() != s[i].toLowerCase()
                  ) {
                    if (
                      d[i] != s[i] &&
                      s[i + 1] != undefined &&
                      d[i + 1].toLowerCase() == s[i + 1].toLowerCase()
                    ) {
                      //document.writeln('n'+c)
                      //document.writeln(s[i])
                      console.log(mdaa);
                      console.log(s);
                      let start = i;
                      let deleteCount = 0;
                      //scale.splice(start, 1)

                      scale.splice(
                        start,
                        deleteCount,
                        d[i] + "<span class='wrongword'>" + s[i] + "</span>"
                      );
                      s.splice(start, 1);
                      s.splice(start, deleteCount, d[i]);
                    } else {
                      //document.writeln('n'+c)
                      //document.writeln(s[i])
                      console.log(mdaa);
                      console.log(s);
                      let start = i;
                      let deleteCount = 0;
                      scale.splice(start, deleteCount, d[i]);
                      c += d[i].length + 1;
                      // s.splice(start, 1)
                      //s.splice(start, deleteCount, d[i])
                    }
                  } else {
                    let start = i;
                    let deleteCount = 0;
                    scale.splice(
                      start,
                      deleteCount,
                      "<span class='wrongword'>" + d[i] + "</span>"
                    );
                    s.splice(start, deleteCount, d[i]);

                    // console.log(s)
                    //document.writeln('g'+c)
                    //document.writeln(s[i])
                  }
                }
              } else {
                //c += s[i].length + 1;
                //  console.log(s)
                let start = i;
                let deleteCount = 0;
                scale.splice(
                  start,
                  deleteCount,
                  d[i] + "<span class='wrongword'>" + s[i] + "</span>"
                );
                s.splice(start, deleteCount, d[i]);
              }
            } else {
              //document.writeln('k'+c)
              //document.writeln(s[i])
              // console.log(s)
              let start = i;
              let deleteCount = 0;
              scale.splice(
                start,
                deleteCount,
                d[i] + "<span class='wrongword'>" + s[i] + "</span>"
              );
              s.splice(start, deleteCount, d[i]);
            }
          }
        }
      } else {
        // console.log(s)
        let start = i;
        let deleteCount = 0;
        s.splice(start, deleteCount, d[i]);
        scale.splice(
          start,
          deleteCount,
          "<span class='wrongword'>" + d[i] + "</span>"
        );
      }
      //document.writeln(c)
    }

    //document.writeln(c)

    if (c > 0) {
      dd = c - 1;
    } else {
      dd = c;
    }

    var time = 30;

    var char = dd;

    var word = char / 5;

    // var hms = '02:04:33';   // your input string
    //console.log(rmTm)

    if (rmTm != undefined) {
      var a = rmTm.split(":"); // split it at the colons
      var total_time = "00:" + minute + ":00";
      var ttime = total_time.split(":");
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var secons = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      var ttsecons = +ttime[0] * 60 * 60 + +ttime[1] * 60 + +ttime[2];

      var ttt = ttsecons - secons;

      var min = ttt / 60;
      //console.log(min)
      console.log(ttt);
    }

    var wpm = Math.round(word / min);

    //console.log(Math.round(wpm))

    var accurac = (dd / totaldep) * 100;
    var accuracy = Math.round((accurac + Number.EPSILON) * 100) / 100;
    var accurcharacter = dd;

    //var wrongp = (totaldep - dd)/(totaldep)*100
    var wrongp = 100 - accuracy;
    var wrongper = Math.round((wrongp + Number.EPSILON) * 100) / 100;
    var wrongcharacter = totaldep - dd;

    //var sped = (actualdep*60)/ttt
    var netsped = (dd * 60) / ttt;
    var netspeed = Math.round(netsped / 5);

    var grosssped = (message.length * 60) / ttt;
    var grossspeed = Math.round(grosssped / 5);

    console.log(ttt);
    //console.log(s)

    //setWpm(Math.round(wpm))
    let paragraph = scale.join(" ");
    //let paragraph = base64(paragrap)
    //var paragraph = ''

    let typing_performance_result = {
      email_id: cookies.email_id,
      paper_code: testcode,
      student_paragraph: message,
      paragraph: paragraph,
      accuracy: accuracy,
      wrong: wrongper,
      grossspeed: grossspeed,
      totaldepres: totaldep,
      accuratedep: accurcharacter,
      wrongdep: wrongcharacter,
      actualdep: dd,
      speed: netspeed,
      time: rmTm,
    };
    let typing_performance_row = await fetch("/typingPerformance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(typing_performance_result),
    });
    typing_performance_row = await typing_performance_row.json();

    navigate(
      `/typingperformance/${accuracy}/${wrongper}/${dd}/${netspeed}/${testcode}/${exam}/${category}`
    );
  };

  if (rmTm == "00:00:00") {
    messageSubmit();
  }

  return (
    <>
      <Row className="tt-fs-r">
        <Col md={12}>
          <p>Typing Test 2023</p>
        </Col>
      </Row>

      <Row className="tt-se-r">
        <Col md={6} className="tt-se-rc6">
          <p className="tt_se-rc6-p">SSC {exam} T2 English</p>
        </Col>
        <Col md={6} className="tt-se-rrc6">
          <p className="tt-se-rrc6-p">Instructions</p>
        </Col>
      </Row>
      <Row className="tt-th-r">
        <Col className="tt-th-rfdiv">
          <Button className="tt-th-rfdiv-button1">Group 1</Button>
          <Button className="tt-th-rfdiv-button2">Group 2</Button>
          <Button className="tt-th-rfdiv-button3">Group 3</Button>
        </Col>

        <Col className="tt-th-rsdiv">
          <Col className="tt-th-rsdiv-firstchild">
            <Col className="tt-th-rsdiv-secondchild">
              <span className="tt-th-rsdiv-secondchild-span">
                Time Left :{" "}
                {typing == true ? (
                  <TypingTimer
                    hoursMinSecs={hoursMinSecs}
                    rmTimeFun={rmTimeFun}
                  />
                ) : (
                  ""
                )}{" "}
              </span>
            </Col>
            <Col className="tt-th-rsdiv-border"></Col>
          </Col>
          <Col className="tt-th-rs-userdiv"></Col>
        </Col>
      </Row>
      <Row className="tt-fr-r">
        <Col md={12} className="tt-fr-rcol12">
          <span className="tt-fr-rcol-span1">Keyboard Layout: Inscript</span>
          <span className="tt-fr-rcol-span2">Language: English</span>
        </Col>
      </Row>
      <Row className="tt-fth-r">
        <Col md={6} className="tt-fth-rcol6">
          <Row className="tt-subfth-r">
            <Col md={12} className="tt-subfth-rcol12" readOnly>
              <Col className="tt-subfth-rcol12-textarea1" readOnly>
                {d}
              </Col>
            </Col>
            <Col md={12} className="tt-subfth-rcol12">
              <textarea
                className="tt-subfth-rcol12-textarea2"
                value={message}
                spellcheck="false"
                onChange={handleMessageChange}
                maxlength={contentLength}
              ></textarea>
            </Col>
          </Row>
        </Col>
        <Col md={1}></Col>
        <Col md={4} className="tt-fth-rcol4">
          <Col className="tt-fth-rcol4-div1">
            <p className="tt-fth-rcol4-div1p">English</p>
          </Col>
          <Col className="tt-fth-rcol4-div2">
            <p className="tt-fth-rcol4-div2p">
              <span className="tt-fth-rcol4-span1">Select Language:</span>
              <span className="tt-fth-rcol4-span2">English(US)</span>
              <span className="tt-fth-rcol4-span3">Move</span>
            </p>
          </Col>
          <Col className="tt-fth-rcol4-div-submit">
            <Button
              className="tt-fth-rcol4-div-submit-button"
              onClick={messageSubmit}
            >
              Submit
            </Button>
          </Col>
        </Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};

export default TypingModule;
