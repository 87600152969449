import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Instruction from "./Components/Instruction";
import InstructionSection from "./Components/InstructionSection";
import InstructionNew from "./Components/InstructionNew";
import InstructionSectionNew from "./Components/InstructionSectionNew";
import InstructionMtsSection from "./Components/InstructionMtsSection";
import InstructionMtsSectionNew from "./Components/InstructionMtsSectionNew";
import Test from "./Components/Test";
import Test5 from "./Components/Test5";
import TestSection from "./Components/TestSection";
import Attempted from "./Components/Attempted";
import AttemptedSection from "./Components/AttemptedSection";
import AttemptedMtsSection from "./Components/AttemptedMtsSection";
import AttemptedTest2 from "./Components/AttemptedTest2";
import Solution from "./Components/Solution";
import SolutionSection from "./Components/SolutionSection";
import SolutionMtsSection from "./Components/SolutionMtsSection";
import SolutionTest from "./Components/SolutionTest";
import CategoryExam from "./Components/CategoryExam";
import TestSeriesSingle from "./Components/TestSeriesSingle";
import TestOverview from "./Components/TestOverview";
import TestseriesList from "./Components/TestseriesList";
import TestSeriesUnlock from "./Components/TestSeriesUnlock";
import TestSeriesUnlockSection from "./Components/TestSeriesUnlockSection";
import SpecificTestseries from "./Components/SpecificTestseries";
import TestSeries from "./Components/TestSeries";
import Faq from "./Components/Faq";
import TestSeriesHeader from "./Components/TestSeriesHeader";
import CarouselTest from "./Components/CrousalTest";
import TestSeriesFooter from "./Components/TestSeriesFooter";
import LiveTestResult from "./Components/LiveTestResult";
import AttemptedTest from "./Components/AttemptedTest";
import Typing from "./Typing";
import TypingModule from "./TypingModule";
import TypingPerformance from "./TypingPerformance";
import TypingPerformanceTest from "./TypingPerformanceTest";
import TypingParagraph from "./TypingParagraph";
import TypingExamSelection from "./TypingExamSelection";
import TestMtsSection from "./Components/TestMtsSection";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/Test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
          element={<Test />}
        />
        <Route
          path="/Test5/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
          element={<Test5 />}
        />
        <Route
          path="/testsection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
          element={<TestSection />}
        />
        <Route
          path="/testmtssection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
          element={<TestMtsSection />}
        />
        <Route
          path="/instruction/:exam_mode_id/:papercode/:test_series_id/:test_title"
          element={<Instruction />}
        />
        <Route
          path="/instructionsection/:exam_mode_id/:papercode/:test_series_id/:test_title"
          element={<InstructionSection />}
        />
        <Route
          path="/instructionmtssection/:exam_mode_id/:papercode/:test_series_id/:test_title"
          element={<InstructionMtsSection />}
        />
        <Route
          path="/InstructionNew/:exam_mode_id/:papercode/:test_series_id"
          element={<InstructionNew />}
        />
        <Route
          path="/InstructionSectionNew/:exam_mode_id/:papercode/:test_series_id"
          element={<InstructionSectionNew />}
        />
        <Route
          path="/InstructionMtsSectionNew/:exam_mode_id/:papercode/:test_series_id"
          element={<InstructionMtsSectionNew />}
        />
        <Route
          path="/attempted/:exam_mode_id/:paper_code/:test_series_id/:title"
          element={<Attempted />}
        />
        <Route path="/typingExamSelection" element={<TypingExamSelection />} />
        <Route
          path="/attemptedsection/:exam_mode_id/:paper_code/:test_series_id/:title"
          element={<AttemptedSection />}
        />
        <Route
          path="/attemptedmtssection/:exam_mode_id/:paper_code/:test_series_id/:title"
          element={<AttemptedMtsSection />}
        />
        <Route
          path="/attempted2/:exam_mode_id/:paper_code/:test_series_id/:title"
          element={<AttemptedTest2 />}
        />
        <Route
          path="/Solution/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
          element={<Solution />}
        />
        <Route
          path="/SolutionSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
          element={<SolutionSection />}
        />
        <Route
          path="/SolutionMtsSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
          element={<SolutionMtsSection />}
        />
        <Route
          path="/Solutiontest/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
          element={<SolutionTest />}
        />
        <Route path="/categoryexam/:id" element={<CategoryExam />} />
        <Route
          path="/testseriessingle/:tier_id"
          element={<TestSeriesSingle />}
        />
        <Route path="/testseries/:email_id" element={<TestSeries />} />
        <Route path="/testoverview" element={<TestOverview />} />
        <Route
          path="/testserieslist/:exam_mode_id/:exam_post_id"
          element={<TestseriesList />}
        />
        <Route path="/specifictestseries" element={<SpecificTestseries />} />
        <Route
          path="/TestSeriesUnlock/:testid/:exam_post_id/:exam_mode_id"
          element={<TestSeriesUnlock />}
        />
        <Route
          path="/TestSeriesUnlockSection/:testid/:exam_post_id/:exam_mode_id"
          element={<TestSeriesUnlockSection />}
        />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/testseriesheader" element={<TestSeriesHeader />} />
        <Route path="/Carousel" element={<CarouselTest />} />
        <Route path="/testseriesfooter" element={<TestSeriesFooter />} />
        <Route
          path="/LiveTestResult/:exam_mode_id/:paper_code/:test_series_id"
          element={<LiveTestResult />}
        />
        <Route path="/AttemptedTest/:email_id" element={<AttemptedTest />} />
        <Route path="/typing/:email_id" element={<Typing />} />
        <Route
          path="/typingmodule/:testcode/:exam/:category"
          element={<TypingModule />}
        />
        <Route
          path="/typingparagraph/:exam/:category"
          element={<TypingParagraph />}
        />
        <Route
          path="/typingperformance/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
          element={<TypingPerformance />}
        />
        <Route
          path="/typingperformancetest/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
          element={<TypingPerformanceTest />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Instruction from "./Components/Instruction";
// import InstructionSection from "./Components/InstructionSection";
// import InstructionNew from "./Components/InstructionNew";
// import InstructionSectionNew from "./Components/InstructionSectionNew";
// import InstructionMtsSection from "./Components/InstructionMtsSection";
// import InstructionMtsSectionNew from "./Components/InstructionMtsSectionNew";
// import Test from "./Components/Test";
// import Test5 from "./Components/Test5";
// import TestSection from "./Components/TestSection";
// import Attempted from "./Components/Attempted";
// import AttemptedSection from "./Components/AttemptedSection";
// import AttemptedMtsSection from "./Components/AttemptedMtsSection";
// import AttemptedTest2 from "./Components/AttemptedTest2";
// import Solution from "./Components/Solution";
// import SolutionSection from "./Components/SolutionSection";
// import SolutionMtsSection from "./Components/SolutionMtsSection";
// import SolutionTest from "./Components/SolutionTest";
// import CategoryExam from "./Components/CategoryExam";
// import TestSeriesSingle from "./Components/TestSeriesSingle";
// import TestOverview from "./Components/TestOverview";
// import TestseriesList from "./Components/TestseriesList";
// import TestSeriesUnlock from "./Components/TestSeriesUnlock";
// import TestSeriesUnlockSection from "./Components/TestSeriesUnlockSection";
// import SpecificTestseries from "./Components/SpecificTestseries";
// import TestSeries from "./Components/TestSeries";
// import Faq from "./Components/Faq";
// import TestSeriesHeader from "./Components/TestSeriesHeader";
// import CarouselTest from "./Components/CrousalTest";
// import TestSeriesFooter from "./Components/TestSeriesFooter";
// import LiveTestResult from "./Components/LiveTestResult";
// import AttemptedTest from "./Components/AttemptedTest";
// import Typing from "./Typing";
// import TypingModule from "./TypingModule";
// import TypingModuleTest from "./TypingModuleTest";
// import TypingModuleNewTest from "./TypingModuleNewTest";
// import TypingPerformance from "./TypingPerformance";
// import TypingPerformanceTest from "./TypingPerformanceTest";
// import TypingParagraph from "./TypingParagraph";
// import TypingExamSelection from "./TypingExamSelection";
// import TestMtsSection from "./Components/TestMtsSection";

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route
//           path="/Test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<Test />}
//         />
//         <Route
//           path="/Test5/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<Test5 />}
//         />
//         <Route
//           path="/testsection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<TestSection />}
//         />
//         <Route
//           path="/testmtssection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<TestMtsSection />}
//         />
//         <Route
//           path="/instruction/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<Instruction />}
//         />
//         <Route
//           path="/instructionsection/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<InstructionSection />}
//         />
//         <Route
//           path="/instructionmtssection/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<InstructionMtsSection />}
//         />
//         <Route
//           path="/InstructionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionNew />}
//         />
//         <Route
//           path="/InstructionSectionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionSectionNew />}
//         />
//         <Route
//           path="/InstructionMtsSectionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionMtsSectionNew />}
//         />
//         <Route
//           path="/attempted/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<Attempted />}
//         />
//         <Route path="/typingExamSelection" element={<TypingExamSelection />} />
//         <Route
//           path="/attemptedsection/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedSection />}
//         />
//         <Route
//           path="/attemptedmtssection/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedMtsSection />}
//         />
//         <Route
//           path="/attempted2/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedTest2 />}
//         />
//         <Route
//           path="/Solution/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<Solution />}
//         />
//         <Route
//           path="/SolutionSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionSection />}
//         />
//         <Route
//           path="/SolutionMtsSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionMtsSection />}
//         />
//         <Route
//           path="/Solutiontest/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionTest />}
//         />
//         <Route path="/categoryexam/:id" element={<CategoryExam />} />
//         <Route
//           path="/testseriessingle/:tier_id"
//           element={<TestSeriesSingle />}
//         />
//         <Route path="/testseries/:email_id" element={<TestSeries />} />
//         <Route path="/testoverview" element={<TestOverview />} />
//         <Route
//           path="/testserieslist/:exam_mode_id/:exam_post_id"
//           element={<TestseriesList />}
//         />
//         <Route path="/specifictestseries" element={<SpecificTestseries />} />
//         <Route
//           path="/TestSeriesUnlock/:testid/:exam_post_id/:exam_mode_id"
//           element={<TestSeriesUnlock />}
//         />
//         <Route
//           path="/TestSeriesUnlockSection/:testid/:exam_post_id/:exam_mode_id"
//           element={<TestSeriesUnlockSection />}
//         />
//         <Route path="/Faq" element={<Faq />} />
//         <Route path="/testseriesheader" element={<TestSeriesHeader />} />
//         <Route path="/Carousel" element={<CarouselTest />} />
//         <Route path="/testseriesfooter" element={<TestSeriesFooter />} />
//         <Route
//           path="/LiveTestResult/:exam_mode_id/:paper_code/:test_series_id"
//           element={<LiveTestResult />}
//         />
//         <Route path="/AttemptedTest/:email_id" element={<AttemptedTest />} />
//         <Route path="/typing/:email_id" element={<Typing />} />
//         <Route
//           path="/typingmodule/:testcode/:exam/:category"
//           element={<TypingModule />}
//         />
//         <Route
//           path="/typingmoduletest/:testcode/:exam/:category"
//           element={<TypingModuleTest />}
//         />
//         <Route
//           path="/typingmodulenewtest/:testcode/:exam/:category"
//           element={<TypingModuleNewTest />}
//         />
//         <Route
//           path="/typingparagraph/:exam/:category"
//           element={<TypingParagraph />}
//         />
//         <Route
//           path="/typingperformance/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
//           element={<TypingPerformance />}
//         />
//         <Route
//           path="/typingperformancetest/:accuracy/:wrongper/:actualdep/:speed/:halferror/:fullerror/:totalword/:totaldepression/:totalstudentdepression/:totalWordParagraph/:rmTm/:testcode/:exam/:category"
//           element={<TypingPerformanceTest />}
//         />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;

// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Instruction from "./Components/Instruction";
// import InstructionSection from "./Components/InstructionSection";
// import InstructionNew from "./Components/InstructionNew";
// import InstructionSectionNew from "./Components/InstructionSectionNew";
// import InstructionMtsSection from "./Components/InstructionMtsSection";
// import InstructionMtsSectionNew from "./Components/InstructionMtsSectionNew";
// import Test from "./Components/Test";
// import Test5 from "./Components/Test5";
// import TestSection from "./Components/TestSection";
// import Attempted from "./Components/Attempted";
// import AttemptedSection from "./Components/AttemptedSection";
// import AttemptedMtsSection from "./Components/AttemptedMtsSection";
// import AttemptedTest2 from "./Components/AttemptedTest2";
// import Solution from "./Components/Solution";
// import SolutionSection from "./Components/SolutionSection";
// import SolutionMtsSection from "./Components/SolutionMtsSection";
// import SolutionTest from "./Components/SolutionTest";
// import CategoryExam from "./Components/CategoryExam";
// import TestSeriesSingle from "./Components/TestSeriesSingle";
// import TestOverview from "./Components/TestOverview";
// import TestseriesList from "./Components/TestseriesList";
// import TestSeriesUnlock from "./Components/TestSeriesUnlock";
// import TestSeriesUnlockSection from "./Components/TestSeriesUnlockSection";
// import SpecificTestseries from "./Components/SpecificTestseries";
// import TestSeries from "./Components/TestSeries";
// import Faq from "./Components/Faq";
// import TestSeriesHeader from "./Components/TestSeriesHeader";
// import CarouselTest from "./Components/CrousalTest";
// import TestSeriesFooter from "./Components/TestSeriesFooter";
// import LiveTestResult from "./Components/LiveTestResult";
// import AttemptedTest from "./Components/AttemptedTest";
// import Typing from "./Typing";
// import TypingModule from "./TypingModule";
// import TypingPerformance from "./TypingPerformance";
// import TypingPerformanceTest from "./TypingPerformanceTest";
// import TypingParagraph from "./TypingParagraph";
// import TypingExamSelection from "./TypingExamSelection";
// import TestMtsSection from "./Components/TestMtsSection";

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route
//           path="/Test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<Test />}
//         />
//         <Route
//           path="/Test5/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<Test5 />}
//         />
//         <Route
//           path="/testsection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<TestSection />}
//         />
//         <Route
//           path="/testmtssection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids"
//           element={<TestMtsSection />}
//         />
//         <Route
//           path="/instruction/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<Instruction />}
//         />
//         <Route
//           path="/instructionsection/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<InstructionSection />}
//         />
//         <Route
//           path="/instructionmtssection/:exam_mode_id/:papercode/:test_series_id/:test_title"
//           element={<InstructionMtsSection />}
//         />
//         <Route
//           path="/InstructionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionNew />}
//         />
//         <Route
//           path="/InstructionSectionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionSectionNew />}
//         />
//         <Route
//           path="/InstructionMtsSectionNew/:exam_mode_id/:papercode/:test_series_id"
//           element={<InstructionMtsSectionNew />}
//         />
//         <Route
//           path="/attempted/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<Attempted />}
//         />
//         <Route path="/typingExamSelection" element={<TypingExamSelection />} />
//         <Route
//           path="/attemptedsection/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedSection />}
//         />
//         <Route
//           path="/attemptedmtssection/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedMtsSection />}
//         />
//         <Route
//           path="/attempted2/:exam_mode_id/:paper_code/:test_series_id/:title"
//           element={<AttemptedTest2 />}
//         />
//         <Route
//           path="/Solution/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<Solution />}
//         />
//         <Route
//           path="/SolutionSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionSection />}
//         />
//         <Route
//           path="/SolutionMtsSection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionMtsSection />}
//         />
//         <Route
//           path="/Solutiontest/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID"
//           element={<SolutionTest />}
//         />
//         <Route path="/categoryexam/:id" element={<CategoryExam />} />
//         <Route
//           path="/testseriessingle/:tier_id"
//           element={<TestSeriesSingle />}
//         />
//         <Route path="/testseries/:email_id" element={<TestSeries />} />
//         <Route path="/testoverview" element={<TestOverview />} />
//         <Route
//           path="/testserieslist/:exam_mode_id/:exam_post_id"
//           element={<TestseriesList />}
//         />
//         <Route path="/specifictestseries" element={<SpecificTestseries />} />
//         <Route
//           path="/TestSeriesUnlock/:testid/:exam_post_id/:exam_mode_id"
//           element={<TestSeriesUnlock />}
//         />
//         <Route
//           path="/TestSeriesUnlockSection/:testid/:exam_post_id/:exam_mode_id"
//           element={<TestSeriesUnlockSection />}
//         />
//         <Route path="/Faq" element={<Faq />} />
//         <Route path="/testseriesheader" element={<TestSeriesHeader />} />
//         <Route path="/Carousel" element={<CarouselTest />} />
//         <Route path="/testseriesfooter" element={<TestSeriesFooter />} />
//         <Route
//           path="/LiveTestResult/:exam_mode_id/:paper_code/:test_series_id"
//           element={<LiveTestResult />}
//         />
//         <Route path="/AttemptedTest/:email_id" element={<AttemptedTest />} />
//         <Route path="/typing/:email_id" element={<Typing />} />
//         <Route
//           path="/typingmodule/:testcode/:exam/:category"
//           element={<TypingModule />}
//         />
//         <Route
//           path="/typingparagraph/:exam/:category"
//           element={<TypingParagraph />}
//         />
//         <Route
//           path="/typingperformance/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
//           element={<TypingPerformance />}
//         />
//         <Route
//           path="/typingperformancetest/:accuracy/:wrongper/:actualdep/:speed/:testcode/:exam/:category"
//           element={<TypingPerformanceTest />}
//         />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;
