import TestSeriesHeader from './TestSeriesHeader'
import liveimage from '../images/liveimage.avif';
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { FaArrowRight } from "react-icons/fa";
import { useCookies } from 'react-cookie'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

const LiveTestResult = () => {
    const [cookies, setCookie] = useCookies()
    const [LiveResult, setLiveResult] = useState('')
    const [UpcomingResult, setUpcomingResult] = useState()
    const [hasError, setHasError] = useState(false);
    const [UpcommingRegistered, setUpcommingRegistered] = useState('Register')
    const [RegisteredType, setRegisteredType] = useState('Register')
    const [show, setShow] = useState(false);
    const [otpshow, setOtpShow] = useState(false);
    const [showEmail, setEmailShow] = useState(false);
    const [mobile, setMobile] = useState()
    const [Otp, setOtp] = useState()
    const [EnterOtp, setEnterOtp] = useState()
    const [EmailID, setEmailID] = useState()
    const [TestAccess, setTestAccess] = useState()
    const [RegisteredStd, setRegisteredStd] = useState()
    const [PCode, setPCode] = useState()
    const { exam_mode_id, paper_code, test_series_id } = useParams()
    const exam_mode_ids = atob(exam_mode_id)
    const papercode = atob(paper_code)
    const testseriesid = atob(test_series_id)

    useEffect(async () => {
        try {
            let exam_details_request = { 'email_id': cookies.email_id, 'paper_code': papercode, 'test_series_id': testseriesid, 'exam_mode_id': exam_mode_ids }
            let exam_info = await fetch("/LiveTestResult", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
                },
                body: JSON.stringify(exam_details_request)
            })
            let exam_infodssdfsd = await exam_info.json();
            setLiveResult(exam_infodssdfsd.liveResult)
            setUpcomingResult(exam_infodssdfsd.upcomingTest)
            setRegisteredStd(exam_infodssdfsd.upcomingTest['registered_std'])
        } catch (error) {
            setHasError(true);
        }
    }, [])

    useEffect(async () => {
        let test_access = [{ 'email_id': cookies.email_id }]
        let test_access_request = await fetch("/testSeriesAccess", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(test_access)
        });
        let test_access_response = await test_access_request.json();
        setTestAccess(test_access_response[0]['user_status'])
    }, [])

    const handleUpcomingUser = async (e) => {
        let paper_code = (e.target.value)
        let std_details = [{ mobile, Otp, 'email_id': cookies.email_id, 'test_series_id': testseriesid, 'exam_mode_id': exam_mode_ids, paper_code }]
        await fetch('/studentInfo', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(std_details)
        })
        setPCode(paper_code)
        setUpcommingRegistered('Registered')
    }

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleOtp = async () => {
        setOtpShow(true)
        setShow(false)
        let otp_data = { 'mobile': mobile, 'name': 'Student' }
        let otp_request = await fetch("/otp", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(otp_data)
        });
        let otp_response = await otp_request.json();
        setOtp(otp_response.OTP)
    }
    const handleEmail = () => {
        if (EnterOtp == Otp) {
            setOtpShow(false)
            setEmailShow(true)
        } else {
            alert('Please enter the OTP sent to ' + mobile)
        }
    }

    const BuyFun = () => {
        window.location.href = "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-";
    }
    const handleEmailSubmit = async () => {
        let days = 365
        let expires = new Date()
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        setCookie("email_id", EmailID, {
            path: "/",
            expires
        })
        setEmailShow(false)
        let std_details = [{ mobile, Otp, 'email_id': EmailID, 'test_series_id': testseriesid, 'exam_mode_id': exam_mode_ids, papercode }]
        await fetch('/studentInfo', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(std_details)
        })
        setRegisteredType('Registered')
    };

    const MobileFun = (e) => {
        setMobile(e.target.value)
    }

    const OtpFun = (e) => {
        setEnterOtp(e.target.value)
    }
    return (
        <>
            <TestSeriesHeader />
            <Row style={{ margin: "2% auto" }}>
                <Col md={4}>
                    <img src={liveimage} />
                </Col>
                {
                    <Col md={4} style={{ marginTop: "5%" }}>
                        <p style={{ fontSize: '22px', fontWeight: '600', margin: '0' }}>Thank you for attempting</p>
                        <p style={{ fontSize: '22px', fontWeight: '600' }}>{LiveResult.test_title}</p>
                        <p style={{ fontSize: '18px', fontWeight: '600', margin: '2% 0 0 0', color: 'red' }}>Result will be declared on</p>
                        <p style={{ fontSize: '16px', fontWeight: '600', color: 'red' }}>{LiveResult.expired_date} at {LiveResult.expired_time}</p>
                    </Col>
                }
                {
                    TestAccess == 1 ? '' :
                        <Col md={4}>
                            <div className="bg-background">
                                <p className="category-yearly-test-pass">Buy Yearly test pass to unlock all SSC exams tests: 1000+ tests</p>
                                <Row className='margin-none'>
                                    <Col md={6} className="no-padding padding-left15">
                                        <div className="full-width">
                                            <svg className="bg-secondary bg-width" xmlns="http://www.w3.org/2000/svg" width="20.133" height="20.533" viewBox="0 0 20.133 20.533">
                                                <path id="ios-checkmark-circle" d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z" transform="translate(-3.375 -3.375)" fill="#e71134" />
                                            </svg>
                                            <p className="p-color p-size">Latest TCS Pattern</p>
                                        </div>
                                        <div className="full-width">
                                            <svg className="bg-secondary bg-width" xmlns="http://www.w3.org/2000/svg" width="20.133" height="20.533" viewBox="0 0 20.133 20.533">
                                                <path id="ios-checkmark-circle" d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z" transform="translate(-3.375 -3.375)" fill="#e71134" />
                                            </svg>
                                            <p className="p-color p-size">Comprehensive test series</p>
                                        </div>
                                        <div className="full-width">
                                            <svg className="bg-secondary bg-width" xmlns="http://www.w3.org/2000/svg" width="20.133" height="20.533" viewBox="0 0 20.133 20.533">
                                                <path id="ios-checkmark-circle" d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z" transform="translate(-3.375 -3.375)" fill="#e71134" />
                                            </svg>
                                            <p className="p-color p-size">Exam Oriented Solution</p>
                                        </div>
                                        <div className="full-width">
                                            <svg className="bg-secondary bg-width" xmlns="http://www.w3.org/2000/svg" width="20.133" height="20.533" viewBox="0 0 20.133 20.533">
                                                <path id="ios-checkmark-circle" d="M13.442,3.375A10.166,10.166,0,0,0,3.375,13.642,10.166,10.166,0,0,0,13.442,23.908,10.166,10.166,0,0,0,23.508,13.642,10.166,10.166,0,0,0,13.442,3.375ZM18.6,10.8l-6.47,6.629h0a.867.867,0,0,1-.562.272.842.842,0,0,1-.566-.281L8.282,14.658a.2.2,0,0,1,0-.281l.862-.879a.185.185,0,0,1,.271,0l2.149,2.192,5.9-6.066a.19.19,0,0,1,.136-.059.175.175,0,0,1,.136.059l.847.894A.2.2,0,0,1,18.6,10.8Z" transform="translate(-3.375 -3.375)" fill="#e71134" />
                                            </svg>
                                            <p className="p-color p-size">Detailed Analysis</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <img src="https://ssccglpinnacle.com/images/Group 36.png" />
                                    </Col>
                                </Row>
                                <button className="unlock-button unlock-button-padding" onClick={RegisteredStd == '0' ? handleShow : BuyFun}>
                                    Unlock all exams
                                </button>
                            </div>
                        </Col>
                }
            </Row>
            <Row style={{ margin: '3% auto' }}>
                <Col>
                    <div className="section-first-category-exam-page">
                        <div className="test-pass-container">
                            <div className="margin-upper full-width">
                                <h2 className="recommended-heading">Upcoming Mega Mock Challenge</h2>
                                <div className="main-div">
                                    <div className="scroller-button top34-percent transform0"><svg id="arrow-ios-forward-outline" width="10.589" height="21.047" viewBox="0 0 10.589 21.047"><path id="arrow-ios-forward-outline-2" data-name="arrow-ios-forward-outline" d="M15,28.5a1.5,1.5,0,0,1-1.155-2.46L20.565,18l-6.48-8.055A1.533,1.533,0,1,1,16.5,8.055l7.245,9a1.5,1.5,0,0,1,0,1.905l-7.5,9A1.5,1.5,0,0,1,15,28.5Z" transform="translate(-13.497 -7.456)" fill="#ed5f69"></path></svg></div>
                                    <Row className="main-div-scroller" style={{ width: '100%' }}>
                                        {
                                            UpcomingResult && UpcomingResult.map((item, k) =>
                                                <Col md={4} key={k}>
                                                    <div className="alltestseries-exam-category-div full-width div-padding">
                                                        <h6 className="left-algn full-width" style={{ fontWeight: '600' }}>{item.test_title}</h6>
                                                        <p className="left-algn full-width" style={{ fontSize: '14px' }}>Starts on {item.start_date} at {item.start_time}</p>
                                                        <div className="left-right full-width">
                                                            <span>8800+ users have already registered</span>
                                                        </div>
                                                        <div className="bottom-line"> </div>
                                                        <div className="full-width attempt">
                                                            {
                                                                item.registered_std == '1' && item.registered_user == '1' ? <div className="full-width attempt">
                                                                    <span>Registered</span>
                                                                </div> : item.registered_std == '1' && item.registered_user == '0' ? <button value={item.paper_code} onClick={handleUpcomingUser} className="full-width attempt">
                                                                    {item.paper_code == PCode ? UpcommingRegistered : 'Register'}
                                                                    <span style={{ fontSize: '12px' }} value={item.paper_code}><FaArrowRight /></span>
                                                                </button> : <div className="full-width attempt" onClick={handleShow}>
                                                                    <span>{RegisteredType}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <div className='mobilebody'>
                    <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>Continue to purchase</p>
                    <p style={{ textAlign: 'center', fontSize: '13px' }}>Boost your exam preparation with us</p>
                    <input type='number' placeholder='Enter Mobile Number' className='entermobile' onChange={MobileFun} />
                </div>
                <Button onClick={handleOtp} className='continue-btn'>
                    Continue
                </Button>
            </Modal>

            <Modal show={otpshow}>
                <div className='mobilebody'>
                    <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>Enter OTP</p>
                    <p style={{ textAlign: 'center', fontSize: '13px' }}>Please enter the OTP sent to {mobile}</p>
                    <input type='number' placeholder='Enter OTP' className='entermobile' onChange={OtpFun} />
                </div>
                <Button onClick={handleEmail} className='continue-btn'>
                    Submit OTP
                </Button>
            </Modal>

            <Modal show={showEmail}>
                <div className='mobilebody'>
                    <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>Enter Email ID</p>
                    <input type='text' placeholder='Enter Email ID' className='entermobile' onChange={(e) => setEmailID(e.target.value)} />
                </div>
                <Button onClick={handleEmailSubmit} className='continue-btn'>
                    Continue
                </Button>
            </Modal>
        </>
    )
}
export default LiveTestResult