import Accordion from './Accordion';
import { Row, Col } from 'react-bootstrap'

function AccordionData() {
  const accordionData = [
    {
      title: 'Can I take a test multiple times?',
      content: 'No, you can take a test once.'
    },
    {
      title: 'Can I download the test paper and solution after the test?',
      content: 'No, these are online tests, you can not download the questions or solutions of a test.'
    },
    {
      title: 'How long can I see the question and solution of the test after I take the test?',
      content: 'Questions and solutions will be visible to you till your test expires.'
    },
    {
      title: 'Will I get all Tests simultaneously?',
      content: 'Your tests will be active according to a schedule or at regular intervals. If it is mentioned in the test series description of a test series that all tests you will get simultaneously then you will get simultaneously otherwise tests will be active at regular intervals or as per schedule defined in the test series.'
    },
    {
      title: 'When are solutions of tests provided?',
      content: 'Solution of the test is provided after you submit the test for a normal test and for live test, test solution and score will be provided after the time schedule of the live test.'
    },
    {
      title: 'What is the expiry date of the test?',
      content: 'Tests will expire once validity of the test series expires. After expiry you will not be able to take or see the tests.'
    },
    {
      title: 'Are detailed solutions provided for a test?',
      content: 'You will get the answer key and detailed solution of a test after you submit the test.'
    },
    {
      title: 'What is the difference between a live test and normal mock test?',
      content: 'Live tests will be active on a particular day and time which will be mentioned on a live test. Once the time is over, you can not take the test, it will show you inactive or expired. You will not be able to see questions or solutions if you have not participated in the test. While in a normal mock test, you can take the test till the validity of the test series. Advisable not to miss a live test.'
    },
    {
      title: 'Do you provide test PDF?',
      content: 'No, these are online tests and PDF of any test is not provided neither questions nor solution.'
    },
    {
      title: 'Can I share my id and password to my friends?',
      content: 'No, your account may be blocked if it is found to misuse the ID and password. Never share your ID, password or OTP with anybody.'
    },
    {
      title: 'In case if I forget the user ID and password then what is the solution?',
      content: 'You can regenerate your password in the forgot password option. You may contact our support team.'
    },
    {
      title: 'How much is the validity of the test series',
      content: 'While purchasing the test series, in the test series description you will find validity of the test series. It depends which test series you have purchased.'
    },
    {
      title: 'Are tests bilingual or English medium?',
      content: 'In the test series description you will find details whether tests are in English medium or bilingual.'
    },
    {
      title: 'When will a particular test expire?',
      content: 'Live test will expire once time to take the test is over. Mock tests will expire when the validity of the test series expires.'
    },
    {
      title: 'I donot have a computer/laptop, can I still take the test?',
      content: 'It is highly recommended that you should use a laptop or desktop to take the tests. In case you don’t have a laptop/desktop you can take tests through mobile.'
    },
    {
      title: 'What will happen if my internet connection is lost during the test?',
      content: 'Test will resume from where internet connection was lost.'
    },
    {
      title: 'What do you mean by test expiry?',
      content: 'You will not be able to take the test or view the test or solution once the test is expired.'
    },
    {
      title: 'Can I pause the test if I have urgent work?',
      content: 'You can not pause the test in a live test. However you can pause the test in a mock test but you should not misuse it. Assure yourself that you have required time to take the test. This option should be used in exceptional circumstances. Misuse of this feature may result in policy violation.'
    },
    {
      title: 'When will I get access to the test series?',
      content: 'Once you purchase the test series from the website, you will get immediate access to the test series portal.'
    },
    {
      title: 'Can I take a live test the next day or after an allotted time schedule or any other day?',
      content: 'You can not take a live test after the allotted time. Purpose of the live test is to ensure students participate in the test during live tests only.'
    },
    {
      title: 'When a test solution is provided for a live test?',
      content: 'Test solution and analysis report will be provided once the time of live test is over. Suppose live test time is 9:00 am to 9:00 PM. Test solution and analysis report will be provided after 9:00 PM.'
    }
  ];

  return (
    <div>
      <div className="width-100-perc padding-top50">
        <div className="test-pass-container">
          <h4 className='center-align margin-bottom30 text-transform-uppercase'>Tests FAQs regarding mock tests</h4>
          <div className="accordion">
            <Row>
              {accordionData.map(({ title, content }) => (
                <Accordion title={title} content={content} />
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionData;