import './Header1.css'
import './TestSeries.css'
import { Row, Col, Modal, Button, Table } from 'react-bootstrap'
import { useEffect, useState, React } from 'react'
import { useNavigate } from 'react-router-dom'
import AccordionData from './AccordionData'

function Faq() {                           

	return (  
      <> 
        <AccordionData />
	  </>
    )
}      

export default Faq;