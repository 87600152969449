import React, { useState, useEffect } from "react";

const CountDownTimerSection = ({
  hoursMinSecs,
  sectionSubmitstat,
  sectionState,
  sectionS,
  pause,
  rmTimeFun,
}) => {
  const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
  const [[hrass, minass, secass], setTime] = useState([
    hours,
    minutes,
    seconds,
  ]);

  const tick = () => {
    if (sectionState == "true") {
      setTime([hours, minutes, seconds]);
      sectionS(false);
    } else {
      if (hrass == 0 && minass == 0 && secass == 0) {
        //reset()
        sectionSubmitstat(true);
        setTime([0, 0, 0]);
      } else if (minass == 0 && secass == 0) {
        setTime([hrass - 1, 59, 59]);
      } else if (secass == 0) {
        setTime([hrass, minass - 1, 59]);
      } else {
        setTime([hrass, minass, secass - 1]);
      }
    }
  };

  //const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

  React.useEffect(() => {
    const timerId = setInterval(() => {
      if (!pause) {
        tick();
      }
    }, 950);
    return () => clearInterval(timerId);
  });

  let rTem =
    hrass.toString().padStart(2, "0") +
    ":" +
    minass.toString().padStart(2, "0") +
    ":" +
    secass.toString().padStart(2, "0");

  useEffect(() => {
    rmTimeFun(rTem);
  }, [secass]);

  return (
    <>
      {`${hrass.toString().padStart(2, "0")}:${minass
        .toString()
        .padStart(2, "0")}:${secass.toString().padStart(2, "0")}`}
    </>
  );
};

export default CountDownTimerSection;

// import React, { useState, useEffect } from 'react'

// const CountDownTimerSection = ({ hoursMinSecs, sectionSubmitstat, sectionState, sectionS, pause, rmTimeFun }) => {

//     const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
//     const [[hrass, minass, secass], setTime] = useState([hours, minutes, seconds]);

//     const tick = () => {
//         if(sectionState == 'true'){
//             setTime([hours, minutes, seconds]);
//             sectionS(false)
//         }else{
//         if (hrass == 0 && minass == 0 && secass == 0) {
//             //reset()
//             sectionSubmitstat(true)
//             setTime([0, 0, 0]);
//         } else if (minass == 0 && secass == 0) {
//             setTime([hrass - 1, 59, 59]);
//         } else if (secass == 0) {
//             setTime([hrass, minass - 1, 59]);
//         } else {
//             setTime([hrass, minass, secass - 1]);
//         }
//         }
//     };

//     //const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

//     React.useEffect(() => {
//         const timerId = setInterval(() => { if (!pause) { tick() } }, 950);
//         return () => clearInterval(timerId);
//     }, [secass, pause, sectionS]);

//     let rTem = hrass.toString().padStart(2, '0') + ':' + minass.toString().padStart(2, '0') + ':' + secass.toString().padStart(2, '0');

//     useEffect(() => {
//         rmTimeFun(rTem)
//     }, [secass])

//     return (
//         <>
//             {`${hrass.toString().padStart(2, '0')}:${minass
//             .toString()
//             .padStart(2, '0')}:${secass.toString().padStart(2, '0')}`}
//         </>
//     );
// }

// export default CountDownTimerSection;
