import './Header1.css'
import './TestSeries.css'
import { Row, Col } from 'react-bootstrap'
       
function TestSeriesAbout() {  
    
    const about = [
        {    
          src: 'https://baljitdhaka.com/myaccount/images/benefit1png.png',
          heading: 'Latest Pattern',
          paragraph: 'High Quality Questions on Latest Pattern.'
        },
        {    
          src: 'https://baljitdhaka.com/myaccount/images/benefit2png.png',
          heading: 'Improvement Plan',
          paragraph: 'Best practice Platform with improvement plan.'
        },
        {
          src: 'https://baljitdhaka.com/myaccount/images/benefit3png.png',
          heading: 'Performance analysis',
          paragraph: 'Assess knowledge, skills and efficiency and compare with others.'
        },
        {
          src: 'https://baljitdhaka.com/myaccount/images/benefits4.png',
          heading: 'Save and revise',
          paragraph: 'Save questions, solution and report so that lantern on you can revise.'
        }
      ]; 
	              
	return (  
      <> 
        <div className="float-left width-100-perc padding-top50">
		<div className="test-pass-container">           
         <h3 className="center-align">Why take ssccglpinnacle Test Series?</h3>  
         <Row>
            {about.map(({src,heading,paragraph}) => 
             <Col md={4}>
               <div className="card1 margin-top20 margin-bottom20">
               <img className="cardsimage padding-top10 center-align margin-center display-table" src={src} alt="why take testseries image 1" />
               <div class="card-body">            
                 <h6 class="benefits-heading center-align">{heading}</h6>
                 <p class="f-14 cl-p center-align">{paragraph}</p>
               </div>             
               </div>
             </Col> 
            )}
            <Col md={8}>
            <div className="card1 margin-top20 margin-bottom20">
              <Row>
               <Col md={6}>
               <div class="card-body">            
                 <h6 class="benefits-heading center-align">Time and accuracy management</h6>
                 <p class="f-14 cl-p center-align">Subject wise, chapter wise, time , accuracy analysis so that you can have exact ideas of improvement.</p>
               </div>             
               </Col>   
               <Col md={6}>              
               <div class="card-body">               
                 <img className="cardsimage center-align margin-center display-table" src="https://baljitdhaka.com/myaccount/images/benefits5.png" alt="why take testseries image 1" />
               </div>                   
               </Col>   
               </Row>
            </div>
            </Col>
         </Row>
        </div>
        </div>            
	  </>
        
    )
}

export default TestSeriesAbout;