import { Row, Col, Container, Button } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Typing.css";
import { useCookies } from "react-cookie";

const TypingExamSelection = () => {
  const [exam, setExam] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const [acces, setAccess] = useState("");
  const [cookies] = useCookies();

  const onChangeExam = (e) => {
    setExam(e.target.value);
  };

  console.log(cookies.email_id);

  useEffect(async () => {
    let email = { email_id: cookies.email_id };
    let result = await fetch("/checkAccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(email),
    });
    let email_result = await result.json();
    setAccess(email_result["access"]);
  });

  if (acces != "") {
    if (acces != "access") {
      window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${cookies.email_id}`;
    }
  }

  const onChangeCategory = (e) => {
    setCategory(e.target.value);
  };

  const next = async () => {
    if (exam != "" && category != "") {
      navigate(`/typingparagraph/${exam}/${category}`);
    }
  };

  return (
    <>
      <Row className="tm-fr">
        <Col md={12} className="tm-fr-col12">
          <p className="tm-fr-col12p">TCS Typing Software 2023</p>
        </Col>
      </Row>

      <Row className="tm-secr">
        <Col md={4} className="tm-secr-col6">
          <p className="tm-secr-col6-p1">System Name:</p>
          <p className="tm-secr-col6-p2">C005</p>
          <p className="tm-secr-col6-p3">
            Contact Invigilator if the Name and Photograph <br></br> display on
            the screen is not yours.
          </p>
        </Col>
        <Col md={4} className="tm-secr-col4">
          <p className="tm-secr-col6-p1">
            This typing Software is designed based on the real interface of ssc
            exams conducted by TCS
          </p>
        </Col>
        <Col md={4} className="tm-secr-2col6">
          <Col className="tm-secr-50">
            <p className="tm-secr-2col6-p1">Candidate Name:</p>
            <p className="tm-secr-2col6-p2">User 05</p>
            <p className="tm-secr-2col6-p3">
              Subject:{" "}
              <span className="tm-secr-2col6-p3-span">SSC CGL T2 English</span>
            </p>
          </Col>
        </Col>
      </Row>
      <Row className="tp-second">
        <Col md={4}></Col>
        <Col md={3} className="tp-second-col3">
          <h5 className="form-h5">Choose Exam And Category</h5>
          <form>
            <Row className="tp-second-col3-row1">
              <select
                className="tp-second-col3-row1-select"
                onChange={onChangeExam}
                required
              >
                <option selected>Select</option>
                <option>CGL</option>
                <option>CHSL</option>
              </select>
            </Row>
            <Row className="tp-second-col3-row3">
              <select
                className="tp-second-col3-row1-select"
                onChange={onChangeCategory}
                required
              >
                <option selected>Select</option>
                <option>UR</option>
                <option>OBC</option>
                <option>SC</option>
                <option>EWS</option>
              </select>
            </Row>
            <Row className="tp-second-col3-row3">
              <input
                type="button"
                className="form-input3"
                value="Next"
                onClick={next}
              />
            </Row>
          </form>
        </Col>
        <Col md={5}></Col>
      </Row>
    </>
  );
};

export default TypingExamSelection;
