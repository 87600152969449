import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Col } from 'react-bootstrap'

function CarouselTest() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <Row>
      <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={100000}>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span> 
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="alltestseries-exam-category-div full-width div-padding">
            <h6 className="left-algn full-width">3201</h6>
            <p className="left-algn full-width">Expires on 2022-11-10</p>
            <p>Live</p>
            <div className="bottom-line">
            </div>
            <div className="left-right full-width">
              <span>Questions</span>
              <span>100</span>
            </div>
            <div className="left-right full-width">
              <span>Time(mins)</span>
              <span>60</span>
            </div>
            <div className="left-right full-width">
              <span>Maximum Marks</span>
              <span>200</span>
            </div>
            <div className="bottom-line"> </div>
            <div className="full-width attempt">
              <span>Attempt</span>
            </div>
          </div>
        </Col>
      </Carousel>
    </Row>
  )
}
export default CarouselTest;

