import Header5 from './Header5';
import 'bootstrap/dist/css/bootstrap.min.css';
import CountDownTimer5 from './CountDownTimer5'
import { Col } from 'react-bootstrap'          
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
                  

function Test5() {
    const [cookies] = useCookies()
    const { language, papercode, exam_mode_id, test_series_id, paperids } = useParams();
    const languages = atob(language);
    const paper_cod = atob(papercode);
    const exam_mod_id = atob(exam_mode_id);
    const test_seris_id = atob(test_series_id);
    const paper_id = atob(paperids);
    const [paper_code, setPaperCode] = useState();
    const [subjectID, setSubjectID] = useState('');
    const [hrs, setHours] = useState('');
    const [mins, setMinutes] = useState('');
    const [secs, setSeconds] = useState(''); 
    const [pause, setPause] = useState(false);
    const [rmTm, setrmTm] = useState()
    const [Answered, setAnsweredCount] = useState(0)
    const [NotAnswered, setNotAnsweredCount] = useState(0)
    const [Marked, setMarkedCount] = useState(0)
    const [MarkedAnswered, setMarkedAnsweredCount] = useState(0)
    const [NotVisited, setNotVisitedCount] = useState('')
    const [subject_name, setSubject_name] = useState('')
    const [title, setTitle] = useState()
    const [testType, setTestType] = useState()

    useEffect(async () => { 
        let papers = { 'paper_code': paper_cod, 'email_id': cookies.email_id }
     
        let papers_res = await fetch("/paperCodeDetails", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ"
            },
            body: JSON.stringify(papers)
        });

        papers_res = await papers_res.json();
        setPaperCode(papers_res.paper_code)
        setSubjectID(papers_res.subject_id)
        setHours(papers_res.hrs)
        setMinutes(papers_res.mins)
        setSeconds(papers_res.secs)
        setSubject_name(papers_res.subject_name)
        setAnsweredCount(papers_res.answered_count)
        setNotAnsweredCount(papers_res.notanswered_count)
        setMarkedCount(papers_res.marked_count)
        setMarkedAnsweredCount(papers_res.marked_answered_count)
        setNotVisitedCount(papers_res.not_visited)
        setTitle(papers_res.title)
        setTestType(papers_res.test_type)
    }, [paper_cod])

    const hoursMinSecs = { hours: hrs, minutes: mins, seconds: secs }
              
    const rmTimeFun = (rTm) => {            
        setrmTm(rTm)
    }

    return (             
        <>              
            <div className="App">                     
            <Col md={3} style={{ marginLeft: '50%', position: 'absolute' }}>
                   <h2 className='timer mobile-timer'>Time Left: <CountDownTimer5 hoursMinSecs={hoursMinSecs} paper_code={paper_code} Paper_id={paper_id} pause={pause} rmTimeFun={rmTimeFun} /></h2>
               </Col>
                {      
                    title && <Header5 hrs={hrs} mins={mins} rTm={rmTm} test_series_id={test_seris_id} exam_mode_id={exam_mod_id} secs={secs} paper_code={paper_code} subjectID={subjectID} NotVisited={NotVisited} Answered={Answered} NotAnswered={NotAnswered} Marked={Marked} MarkedAnswered={MarkedAnswered} subject_name={subject_name} title={title} languages={languages} pids={paper_id} TestTp={testType}/>
                } 
            </div>
        </>
    );
}

export default Test5;
