import "./Header1.css";
import "./TestSeries.css";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TestSeriesHeader from "./TestSeriesHeader";
import TestSeriesFooter from "./TestSeriesFooter";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";

function TestSeries() {
  const { email_id } = useParams();
  const [cookies, setCookie] = useCookies(["email_id"]);
  const [TestExamData, setTestExamData] = useState([]);
  const [value] = useState(1);
  const [SpecExamData, setSpecExamData] = useState([]);
  const navigate = useNavigate();

  var url = window.location.href;
  var user = url.split("?UserID=");
  var userids;
  var userids = user != undefined ? (userids = user["1"]) : "";
  var useridss;
  useridss = userids != undefined ? userids.split("&CampaignID=") : "";
  var userid = useridss["0"];
  var useridsss = useridss["1"];
  var campaign;
  var campaign = useridsss != undefined ? useridsss.split("&BannerID=") : "";
  var campaignid = campaign["0"];
  var bannerid = campaign["1"];

  let days = 365;
  let expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  setCookie("email_id", email_id, {
    path: "/",
    expires,
  });

  useEffect(async () => {
    let testseries_student_email_id = [{ email_id: email_id }];
    let testseries_exam_info = await fetch("/examApi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(testseries_student_email_id),
    });
    let testseries_exam_info_data = await testseries_exam_info.json();
    setTestExamData(testseries_exam_info_data);
  }, []);

  const categorie = (event) => {
    let id = event.target.getAttribute("data-value");
    userid != undefined
      ? navigate(
          "/CategoryExam/" +
            id +
            "?UserID=" +
            userid +
            "&CampaignID=" +
            campaignid +
            "&BannerID=" +
            bannerid
        )
      : navigate("/CategoryExam/" + id);
  };

  useEffect(async () => {
    let exam_id_spec = [{ email_id: email_id, exam_id: 1 }];
    let testseries_exam_info_spec = await fetch("/SpecificExam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(exam_id_spec),
    });
    let testseries_exam_info_spec_data = await testseries_exam_info_spec.json();
    console.log(testseries_exam_info_spec_data);
    setSpecExamData(testseries_exam_info_spec_data);
  }, []);

  async function changeValue(e, val) {
    let exam_id_spec = [{ email_id: email_id, exam_id: val }];
    let testseries_exam_info_spec = await fetch("/SpecificExam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer YOS8V94dc1Snf7pAkfm4M6bvv7EZEqDGCXkfwxHZ",
      },
      body: JSON.stringify(exam_id_spec),
    });
    let testseries_exam_info_spec_data = await testseries_exam_info_spec.json();
    console.log(testseries_exam_info_spec_data);
    setSpecExamData(testseries_exam_info_spec_data);
  }

  async function postTier(e) {
    let tier_id = e.target.dataset.tier;
    navigate("/TestSeriesSingle/" + tier_id);
  }

  const memozideHeader = useMemo(() => {
    return <TestSeriesHeader />;
  }, []);

  return (
    <>
      {/*  <TestSeriesHeader /> */}
      {memozideHeader}
      <div className="section-testpass">
        <div className="test-pass-container">
          <Row className="testpass-heading">
            <Col md={12}>
              <h2 className="test-pass-h2">Yearly Test Pass Pass</h2>
            </Col>
            <Col md={12}>
              <p className="test-pass-p">
                Get Online Test Series based on latest TCS pattern with advance
                features
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="test-pass-div">
              <img src="https://ssccglpinnacle.com//images/test-pass-section1.png" />
              <p>Latest TCS Pattern tests Real exam experience</p>
            </Col>
            <Col md={3} className="test-pass-div">
              <img src="https://ssccglpinnacle.com//images/test-pass-section2.png" />
              <p>
                Mock Tests, PYP, Chapter wise, Sectional Tests, Result Oriented
                Tests
              </p>
            </Col>
            <Col md={3} className="test-pass-div">
              <img src="https://ssccglpinnacle.com//images/test-pass-section3.png" />
              <p>Detailed and Result Oriented Solution, Immediate Result</p>
            </Col>
            <Col md={3} className="test-pass-div">
              <img src="https://ssccglpinnacle.com//images/test-pass-section4.png" />
              <p>Detailed Report, Performance Analysis, Improvement Plan</p>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section-exam-category">
        <div className="test-pass-container">
          <h2 className="select-your-exam-category">
            Select Your Exam Category
          </h2>
          <p className="select-your-exam-subp">
            Click to view all test series in the exam
          </p>
          <Row>
            {TestExamData.map((ted, i) => (
              <Col md={4} key={i}>
                <div
                  className="exam-category-div"
                  data-value={ted.exam_id}
                  onClick={categorie}
                >
                  <img
                    className="exam-category-img"
                    src={ted.logo}
                    data-value={ted.exam_id}
                  />
                  <p className="exam-category-p" data-value={ted.exam_id}>
                    {ted.exam_name} Exams{" "}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="all-test-series">
        <div className="test-pass-container">
          <h2 className="select-your-exam-category">
            All Tests Series and Mock tests
          </h2>
          <p className="select-your-exam-subp">
            Click to view test series and mock tests for all exams
          </p>
          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={changeValue}
          >
            {TestExamData.map((td, j) => (
              <Tab
                className="br9 padding0 margin-right16 min-height30 backgound-color-inherit background-image color-white"
                value={td.exam_id}
                label={td.exam_name}
                key={j}
              />
            ))}
          </Tabs>

          {SpecExamData ? (
            <Row>
              {SpecExamData.map((sed) => (
                <>
                  {sed.exam_post.map((sep, l) => (
                    <>
                      <Col md={4} key={l}>
                        <div
                          className="alltestseries-exam-category-div"
                          data-value={sed.exam_id}
                          data-tier={sep.exam_post_tier_id}
                          onClick={postTier}
                        >
                          <img
                            className="exam-category-img"
                            data-value={sed.exam_id}
                            data-tier={sep.exam_post_tier_id}
                            src={sed.logo}
                          />
                          <p
                            className="exam-category-p"
                            data-value={sed.exam_id}
                            data-tier={sep.exam_post_tier_id}
                          >
                            {sep.exam_post_tier}
                          </p>
                        </div>
                      </Col>
                    </>
                  ))}
                </>
              ))}
            </Row>
          ) : (
            <Row>
              {TestExamData.map((tedmp) => (
                <>
                  {tedmp.exam_post.map((epm, l) => (
                    <>
                      <Col md={4} key={l}>
                        <div className="alltestseries-exam-category-div">
                          <img className="exam-category-img" src={tedmp.logo} />
                          <p className="exam-category-p">
                            {epm.exam_post_tier}
                          </p>
                        </div>
                      </Col>
                    </>
                  ))}
                </>
              ))}
            </Row>
          )}

          <h2>Pinnacle Test pass</h2>
          <p>
            In this test pass SSC exams are covered currently. In due course we
            will add other exams category also which are showing here. For the
            moment those students who are preparing for SSC CGL Tier 2, SSC
            CHSL, SSC MTS, Delhi Police, Steno, SSC GD students join Pinnacle
            test pass. In a single test pass students will get access of all
            test series of SSC exams. Each Test series has many tests. Like SSC
            CGL Tier 2 test series has 80 mock tests based on TCS pattern and
            and previous year papers conducted by TCS are also included.
          </p>
          <h2>Test Pass includes</h2>
          <p>- Mock tests based on TCS pattern</p>
          <p>- TCS Previous year papers</p>
          <p>- Sectional Tests</p>
          <p>- Chapter wise Tests</p>
          <p>- Misc. Tests</p>
        </div>
      </div>

      <TestSeriesFooter />
    </>
  );
}
export default TestSeries;
